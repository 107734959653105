import React from "react";

import { Link } from "react-router-dom";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { AppBar, Box, Button, Stack, Toolbar, Typography } from "@mui/material";

import logo from "../assets/kavall_header_logo.png";
import { useAuthState } from "../authentication/AuthState";

export default function Header() {
  const authState = useAuthState();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ backgroundColor: "#6620D4", height: 61, zIndex: 3 }}
    >
      <Toolbar>
        <Stack direction="row" sx={{ width: "100%" }} spacing={1}>
          <Box
            component={Link}
            to="/"
            sx={{
              width: "167px",
              height: "27px",
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="Kavall-logo" height={30} />
          </Box>
          <Box
            sx={{
              color: "#FFFFFF",
              flexGrow: 1,
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                marginRight: 4.625,
                justifyContent: "flex-end",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.66)",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "130%",

                  textAlign: "right",
                  letterSpacing: "-0.02em",
                }}
              >
                Authorized as:
              </Typography>
              <Typography>&nbsp;</Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "130%",

                  textAlign: "right",
                  letterSpacing: "-0.02em",
                }}
              >
                {authState.status === "authorized" ? authState.email : ""}
              </Typography>
            </Stack>
          </Box>
          <Stack
            direction="row"
            component={Button}
            onClick={authState.logout}
            justifyContent="center"
            alignItems="center"
            spacing={1.375}
            sx={{
              width: "98px",
              height: "29px",
              color: "#FFFFFF",
              textDecoration: "none",
            }}
          >
            <LogoutOutlinedIcon sx={{ width: "24px", height: "24px" }} />
            <Typography
              sx={{
                width: "66px",
                height: "21px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "130%",
                letterSpacing: "-0.01em",
                color: "#FFFFFF",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            >
              Log Out
            </Typography>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
