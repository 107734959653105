import React from "react";

import DashboardCard from "./DashboardCard";
import PickersGrid from "./PickersGrid";

const Pickers = () => (
  <DashboardCard title={"Pickers"} sx={{ overflowY: "auto" }}>
    <PickersGrid />
  </DashboardCard>
);

export default Pickers;
