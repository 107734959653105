export function concatRidersName(firstName: string, lastName: string) {
  const firstNameConcat = firstName.substring(0, 3).toUpperCase();
  const lastNameConcat = lastName.substring(0, 3).toUpperCase();

  return `${firstNameConcat || "***"}${lastNameConcat || "***"}`;
}

export function concatVehicleType(vehicleType: string) {
  switch (vehicleType) {
    case "small_bike":
      return "Small";
    case "medium_bike":
      return "Medium";
    case "large_bike":
      return "Large";
    case "xl_bike":
      return "X-Large";
    default:
      return "Unknown";
  }
}
