import React from "react";

import { Typography } from "@mui/material";

const OrderNumber = (props: { number: string }) => (
  <Typography sx={{ fontWeight: 700, fontSize: 24, color: "#202020" }}>
    {props.number.replace("PO-", "")}
  </Typography>
);

export default OrderNumber;
