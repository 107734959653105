import React from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

const DashboardSpinner = () => (
  <Box
    sx={{
      display: "flex",
      minWidth: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
    }}
  >
    <CircularProgress />
    <Typography sx={{ marginLeft: 1 }}>Loading orders...</Typography>
  </Box>
);

export default DashboardSpinner;
