import React, { useState } from "react";

import { Check, PriorityHigh } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid";

import { Rider } from "../../data/Performance";
import { usePerformanceDashboardState } from "../../pages/PerformanceDashboard/PerformanceDashboardState";
import DashboardSpinner from "../DashboardSpinner";
import PerformanceDataGrid from "./PerformanceDataGrid";
import RiderGridHeader from "./RiderGridHeader";
import { gridFontSize } from "./styles";
import { toHoursMinutes } from "./utils";

const renderStatusCell = (params: GridRenderCellParams<any, Rider, any>) => {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
      <Typography fontSize={gridFontSize}>{params.row.status}</Typography>
      <Typography fontSize={gridFontSize}>
        ({toHoursMinutes(params.row.status_duration_minutes)})
      </Typography>
    </Box>
  );
};

const renderAverageRoundTripCell = (
  params: GridRenderCellParams<any, Rider, any>
) => (
  <Box display="flex" justifyContent="center" sx={{ width: "100%" }} gap={1}>
    <Typography fontSize={gridFontSize}>
      {Math.round(params.row.avg_round_trip_minutes || 0)} min
    </Typography>
    <Typography fontSize={gridFontSize}>
      {" "}
      ({params.row.avg_round_trip_km?.toFixed(1) || 0} km)
    </Typography>
  </Box>
);

const renderWorkedTimeCell = (
  params: GridRenderCellParams<any, Rider, any>
) => {
  return toHoursMinutes(params.row.worked_time_minutes);
};

const columns: GridColDef[] = [
  {
    headerName: "Name",
    flex: 2.5,
    field: "name",
    valueGetter: (params) => params?.row?.name,
  },
  {
    headerName: "Status",
    field: "status",
    flex: 3.5,
    renderCell: renderStatusCell,
  },
  {
    headerName: "Orders",
    field: "orders",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "AVG round trip",
    field: "avg_round_trip",
    renderCell: renderAverageRoundTripCell,
    headerAlign: "center",
    flex: 2.5,
  },
  {
    headerName: "Worked time (today)",
    field: "worked_time_minutes",
    flex: 2.3,
    renderCell: renderWorkedTimeCell,
  },
];

const RidersDataGrid = (props: {
  rows?: ReadonlyArray<Rider>;
  justHeader?: boolean;
  hideHeader?: boolean;
  sortModel: GridSortModel;
  setSortModel?: (sortModel: GridSortModel) => void;
}) => {
  return (
    <PerformanceDataGrid
      columns={columns}
      getRowId={(row: Rider) => row.rider_id}
      {...props}
    />
  );
};

const OperationalRidersGrid = (props: {
  operationalRiders: Rider[];
  sortModel: GridSortModel;
}) => (
  <Box>
    <RiderGridHeader
      icon={<Check sx={{ color: "#FFF", fontSize: gridFontSize }} />}
      color="#16D278"
    >
      {"Operational riders (punched in & operational)"}
    </RiderGridHeader>

    <RidersDataGrid
      rows={props.operationalRiders}
      sortModel={props.sortModel}
      hideHeader
    />
  </Box>
);

const UnplannedRidersGrid = (props: {
  operationalRiders: Rider[];
  sortModel: GridSortModel;
}) => (
  <Box>
    <RiderGridHeader
      icon={<Check sx={{ color: "#FFF", fontSize: gridFontSize }} />}
      color="#0096FF"
    >
      {"Operational, not punched in"}
    </RiderGridHeader>

    <RidersDataGrid
      rows={props.operationalRiders}
      sortModel={props.sortModel}
      hideHeader
    />
  </Box>
);

const PunchedInRidersGrid = (props: {
  punchedInRiders: Rider[];
  sortModel: GridSortModel;
}) => (
  <>
    <RiderGridHeader
      icon={<PriorityHigh sx={{ color: "#FFF", fontSize: gridFontSize }} />}
      color="#FFCB14"
    >
      Punched in, not operational
    </RiderGridHeader>
    <RidersDataGrid
      rows={props.punchedInRiders}
      hideHeader
      sortModel={props.sortModel}
    />
  </>
);

const LazyRidersGrid = (props: {
  lazyRiders: Rider[];
  sortModel: GridSortModel;
}) => (
  <>
    <RiderGridHeader
      icon={<CloseIcon sx={{ color: "#FFF", fontSize: gridFontSize }} />}
      color="#E95757"
    >
      In planday but not punched in
    </RiderGridHeader>
    <RidersDataGrid
      rows={props.lazyRiders}
      hideHeader
      sortModel={props.sortModel}
    />
  </>
);

const RidersGrid = () => {
  const { riders, isLoadingPerformance } = usePerformanceDashboardState();

  const [sortModel, setSortModel] = useState<
    { field: string; sort: GridSortDirection }[]
  >([]);

  if (!riders || isLoadingPerformance) {
    return <DashboardSpinner />;
  }

  const operationalRiders = riders.filter(
    (rider) => rider.operational && rider.punched_in
  );
  const unplannedRiders = riders.filter(
    (rider) => rider.operational && !rider.punched_in && rider.status !== "Late"
  );
  const punchedInRiders = riders.filter(
    (rider) => !rider.operational && rider.punched_in
  );
  const lazyRiders = riders.filter(
    (rider) =>
      rider.status === "Late" || (!rider.operational && !rider.punched_in)
  );

  return (
    <Box flexGrow={1} marginTop={1.5} fontSize="14px !important">
      <RidersDataGrid
        sortModel={sortModel}
        setSortModel={setSortModel}
        justHeader
      />
      {operationalRiders.length > 0 && (
        <OperationalRidersGrid
          operationalRiders={operationalRiders}
          sortModel={sortModel}
        />
      )}
      {unplannedRiders.length > 0 && (
        <UnplannedRidersGrid
          operationalRiders={unplannedRiders}
          sortModel={sortModel}
        />
      )}
      {punchedInRiders.length > 0 && (
        <PunchedInRidersGrid
          punchedInRiders={punchedInRiders}
          sortModel={sortModel}
        />
      )}
      {lazyRiders.length > 0 && (
        <LazyRidersGrid lazyRiders={lazyRiders} sortModel={sortModel} />
      )}
    </Box>
  );
};

export default RidersGrid;
