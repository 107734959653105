import React from "react";

import { Box, Typography } from "@mui/material";

import StoreSelect from "./StoreSelect";

const ListHeader = () => (
  <Box display="flex" flexDirection="row">
    <Typography sx={{ fontSize: 32, fontWeight: 700, color: "#252D4A" }}>
      Purchase orders
    </Typography>
    <Typography sx={{ fontSize: 32, color: "#BFBFBF", marginLeft: 1 }}>
      (last 30 days)
    </Typography>
    <Box display="flex" flexGrow={1} sx={{ justifyContent: "flex-end" }}>
      <StoreSelect />
    </Box>
  </Box>
);

export default ListHeader;
