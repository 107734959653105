import React, { ChangeEvent } from "react";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { StaffingStore } from "../../data/StaffingForecast";
import { useStaffingForecast } from "../../pages/StaffingForecast/StaffingForecastState";
import SubmitButton from "./SubmitButton";
import StoreAndDate from "./StoreAndDate";
import OverviewModal from "./OverviewModal";
import AbortButton from "./AbortButton";

type Props = {
  dates: string[];
  stores: StaffingStore[];
  storeInfo: {
    name: string;
    day: string;
    date: string;
  };
  onHandleChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => void;
  onHandleSubmit: (event: React.FormEvent) => void;
  onHandleAbort: () => void;
  isGenerating: boolean;
  isLoading: boolean;
};

const NUMBER_OF_DATES_FORWARD = 6;

const Filters = (props: Props) => {
  const {
    dates,
    stores,
    storeInfo,
    onHandleChange,
    onHandleSubmit,
    onHandleAbort,
    isGenerating,
    isLoading,
  } = props;

  const { filter } = useStaffingForecast();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
  };
  const firstDateIndexInDateToList = dates.indexOf(filter.forecastDateFrom) + 1;

  const dateIntervalForDateToList = dates.slice(
    firstDateIndexInDateToList,
    firstDateIndexInDateToList + NUMBER_OF_DATES_FORWARD
  );

  return (
    <form
      onSubmit={(e: React.FormEvent) => {
        onHandleSubmit(e);
      }}
    >
      <Grid display="flex">
        <Grid item display="flex" flexWrap="wrap" xs={4} gap={1}>
          <FormControl
            sx={{ minWidth: "200px", fontSize: "12px" }}
            variant="standard"
          >
            <InputLabel id="store">Store</InputLabel>
            <Select
              name="storeId"
              labelId="store"
              size="small"
              sx={{ fontSize: "12px" }}
              variant="standard"
              value={String(filter.storeId)}
              onChange={onHandleChange}
            >
              {stores.map((store) => (
                <MenuItem
                  value={store.id}
                  key={store.id}
                  sx={{ fontSize: "12px" }}
                >
                  {store.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            name="orderFactor"
            label="Order Factor"
            id="orderFactor"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
              style: { width: "150px", fontSize: "12px" },
            }}
            onChange={onHandleChange}
            value={filter.orderFactor}
            size="small"
            sx={{ width: "150px" }}
            variant="standard"
          />

          <TextField
            name="putrFactor"
            label="pUTR Factor"
            id="putrFactor"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
              style: { width: "150px", fontSize: "12px" },
            }}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            onChange={onHandleChange}
            value={filter.putrFactor}
            size="small"
            variant="standard"
          />

          <FormControl
            sx={{ minWidth: "200px", fontSize: "12px" }}
            variant="standard"
          >
            <InputLabel id="forecast-date">Date from</InputLabel>
            <Select
              name="forecastDateFrom"
              labelId="forecast-date-from"
              label="Forecast date from"
              size="small"
              sx={{ fontSize: "12px" }}
              variant="standard"
              value={String(filter.forecastDateFrom)}
              onChange={onHandleChange}
              MenuProps={MenuProps}
            >
              {dates.map((date, index) => (
                <MenuItem value={date} key={index} sx={{ fontSize: "12px" }}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{ minWidth: "200px", fontSize: "12px" }}
            variant="standard"
          >
            <InputLabel id="forecast-date">Date to</InputLabel>
            <Select
              name="forecastDateTo"
              labelId="forecast-date-to"
              label="Forecast date to"
              size="small"
              sx={{ fontSize: "12px" }}
              variant="standard"
              value={String(filter.forecastDateTo)}
              onChange={onHandleChange}
              MenuProps={MenuProps}
            >
              {dateIntervalForDateToList.map((date, index) => (
                <MenuItem value={date} key={index} sx={{ fontSize: "12px" }}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            name="absenceFactor"
            label="Absence Factor"
            id="absence-factor"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
              style: { width: "150px", fontSize: "12px" },
            }}
            onChange={onHandleChange}
            value={filter.absenceFactor}
            size="small"
            sx={{ width: "150px" }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={4}>
          <StoreAndDate storeInfo={storeInfo} />
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          gap={1}
          xs={4}
        >
          <Grid item display="flex" gap={1}>
            <OverviewModal />
            <SubmitButton
              isLoading={isLoading}
              isGenerating={isGenerating}
              name="generate"
              content="Generate new shifts"
              theme={{ backgroundColor: "#773fca", color: "#ffffff" }}
            />
            <SubmitButton
              isLoading={isLoading}
              isGenerating={isGenerating}
              name="submit"
              content="Submit shifts"
              theme={{ backgroundColor: "#000000", color: "#ffffff" }}
            />
          </Grid>
          <Grid item display="flex">
            <AbortButton
              onHandleAbort={onHandleAbort}
              content="Cancel"
              isGenerating={isGenerating}
              theme={{ backgroundColor: "crimson", color: "ffffff" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Filters;
