import React from "react";

import { Box, Switch, Typography } from "@mui/material";

const AdvancedViewSwitch = (props: {
  advancedView: boolean;
  setAdvancedView: (advancedView: boolean) => void;
}) => {
  const { advancedView, setAdvancedView } = props;

  const onSwitchClick = () => {
    setAdvancedView(!advancedView);
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Switch value={advancedView} onClick={onSwitchClick} />
      <Typography>Advanced view</Typography>
    </Box>
  );
};

export default AdvancedViewSwitch;
