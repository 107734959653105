import React from "react";

import { Typography } from "@mui/material";

import { darkGrey } from "./styles";

const SubListHeader = ({ name, length }: { name: string; length?: number }) => (
  <>
    <Typography
      variant="h5"
      sx={{
        fontSize: { xs: "19px", md: "13px", lg: "19px", xl: "48px" },
        color: darkGrey,
        fontWeight: 700,
      }}
      component="div"
    >
      {name}
    </Typography>
    {length ? (
      <Typography
        sx={{
          marginLeft: 1,
          color: darkGrey,
          fontSize: { xs: "15px", md: "11px", lg: "15px", xl: "38px" },
        }}
      >
        ({length})
      </Typography>
    ) : null}
  </>
);

export default SubListHeader;
