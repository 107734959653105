import React from "react";

import { Box, Typography } from "@mui/material";

import Order from "../../../data/Order";
import { boldFontWeight } from "../styles";

const OrderNumber = (props: {
  order: Order;
  isToRideStackingEnabled: boolean;
  advancedView: boolean;
}) => {
  const { order, isToRideStackingEnabled, advancedView } = props;
  const unlockedToRideOrders =
    !order.isLockedFromRestacking && order.latestStatus === "pickingCompleted";

  const textColor =
    unlockedToRideOrders && isToRideStackingEnabled && !order.isManuallyHandled
      ? "rgba(28, 28, 28, 0.4)"
      : "#1C1C1C";

  const orderNumber = advancedView
    ? String(order.displayNumber)
    : String(order.displayNumber).slice(-4);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "middle",
        verticalAlign: "bottom",
      }}
    >
      <Box>
        {advancedView && (
          <Typography
            component="span"
            sx={{
              verticalAlign: "middle",
              color: textColor,
              fontSize: {
                xs: "12px",
                md: "10px",
                lg: "12px",
                lg2: "20px",
                xl: "46px",
              },
              lineHeight: "95%",
            }}
          >
            {orderNumber.slice(0, orderNumber.length - 4)}
          </Typography>
        )}
        <Typography
          component="span"
          variant="h6"
          sx={{
            verticalAlign: "middle",
            color: textColor,
            fontSize: advancedView
              ? {
                  xs: "18px",
                  md: "12px",
                  lg: "16px",
                  lg2: "25px",
                  xl: "60px",
                }
              : {
                  xs: "25px",
                  md: "18px",
                  lg: "25px",
                  lg2: "32px",
                  xl: "75px",
                },
            lineHeight: "95%",
            fontWeight: boldFontWeight,
          }}
        >
          {orderNumber.slice(-4)}
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderNumber;
