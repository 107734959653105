import React from "react";

import { Check } from "@mui/icons-material";
import { Box } from "@mui/material";

import Order from "../../../data/Order";
import PlannedDeliveryIcon from "../PlannedDelivery";
import FailedDeliveryIcon from "../FailedDeliveryIcon";

const OrderIcons = ({ order }: { order: Order; advancedView: boolean }) => {
  const isPlannedOrder = Boolean(order.plannedOrderCreationTimestamp);
  const wasPickedLessThanTenSecAgo = order.wasPickedLessThanTenSecAgo;
  const isFailedDeliveryOrder = order.isFailedDelivery;

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexShrink={1}
      justifyContent="flex-end"
      alignItems="center"
      gap="3px"
    >
      {isPlannedOrder && <PlannedDeliveryIcon />}
      {isFailedDeliveryOrder && <FailedDeliveryIcon />}

      {wasPickedLessThanTenSecAgo && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Check
            sx={{
              color: "#00D968",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default OrderIcons;
