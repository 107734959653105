import React from "react";

import { TransitionGroup } from "react-transition-group";

import { Collapse, List, ListItem } from "@mui/material";

import Order from "../../data/Order";
import ListHeader from "./ListHeader";
import OrderCard from "./OrderCard/OrderCard";
import { listBorder, listBorderRadius, listPadding } from "./styles";
import { AvailableRider, DashboardRider } from "../../data/Riders";

export enum DashboardColumn {
  toPick = "toPick",
  picking = "picking",
  toRide = "toRide",
  riding = "riding",
  delivered = "delivered",
}

const OrderListItem = (props: {
  order: Order;
  columnOrders: Order[];
  orders: Order[];
  riders: DashboardRider[] | undefined;
  availableRiders: AvailableRider[] | undefined;
  advancedView: boolean;
  isToRideStackingEnabled: boolean;
}) => (
  <ListItem
    sx={{
      padding: 0,
    }}
  >
    <OrderCard {...props} />
  </ListItem>
);

const OrdersList = (props: {
  name: string;
  columnOrders: Order[];
  orders: Order[];
  riders: DashboardRider[] | undefined;
  availableRiders: AvailableRider[] | undefined;
  dashboardColumn: DashboardColumn;
  advancedView: boolean;
  isToRideStackingEnabled: boolean;
  fullHeight?: boolean;
  grow?: boolean;
}) => {
  let {
    columnOrders,
    orders,
    riders,
    availableRiders,
    advancedView,
    isToRideStackingEnabled,
  } = props;

  // Sort most recently fulfilled on top
  if (props.dashboardColumn === DashboardColumn.delivered) {
    columnOrders = columnOrders.sort(
      (order1: Order, order2: Order) =>
        new Date(order2.fulfilledTimestamp ?? 0).getTime() -
        new Date(order1.fulfilledTimestamp ?? 0).getTime()
    );
  }

  return (
    <List
      sx={{
        padding: listPadding,
        paddingBottom: 0,
        backgroundColor: "background.default",
        borderRadius: listBorderRadius,
        border: listBorder,
        height: props.fullHeight ? "100%" : undefined,
        flexGrow: props.grow ? 1 : undefined,
        overflowY: { xs: "auto", md: "scroll" },
        overflowX: "hidden",
      }}
    >
      <TransitionGroup>
        <ListItem
          sx={{
            display: "flex",
            paddingBottom: { md: "9px", xl: "36px" },
            paddingLeft: 0,
          }}
        >
          <ListHeader name={props.name} length={props.columnOrders.length} />
        </ListItem>
        {columnOrders.map((order) => (
          <Collapse key={order.displayNumber}>
            <OrderListItem
              order={order}
              columnOrders={columnOrders}
              orders={orders}
              riders={riders}
              availableRiders={availableRiders}
              advancedView={advancedView}
              isToRideStackingEnabled={isToRideStackingEnabled}
            />
          </Collapse>
        ))}
      </TransitionGroup>
    </List>
  );
};

export default OrdersList;
