import Order from "../../../data/Order";
import { AvailableRider } from "../../../data/Riders";
import { concatRidersName } from "./ridersFormatting";

function getStackStatus(orders: Order[], availableRider: AvailableRider) {
  const stacksWithSuggestedRider = orders.filter(
    (order) =>
      order.orderStackId !== null &&
      order.suggestedRider?.name.toLocaleUpperCase() ===
        concatRidersName(availableRider.firstName, availableRider.lastName) &&
      (order.latestStatus === "orderCreated" ||
        order.latestStatus === "pickingStarted" ||
        order.latestStatus === "pickingCompleted")
  );

  const groupedStacks = stacksWithSuggestedRider.reduce((acc, obj) => {
    const key = obj["orderStackId"];

    if (!key) return acc;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key]?.push(obj);
    return acc;
  }, {} as { [key: string]: Order[] });

  const orderStacks = Object.values(groupedStacks);

  // get the sortTimestamp for every stack and convert it to unix for comparasion
  const timestamps = orderStacks.flatMap((stack) =>
    stack.map((stack) => new Date(stack.sortTimestamp).getTime())
  );

  // find the oldest timestamp of all stacks
  const oldestTimestamp = Math.min(...timestamps);

  // find the stack with the oldest timestamp
  const mostUrgentStack = orderStacks.find((stack) =>
    stack.filter(
      (stack) => stack.sortTimestamp === new Date(oldestTimestamp).toISOString()
    )
  );

  const mostUrgentStackId = mostUrgentStack
    ? mostUrgentStack[0]?.orderStackId
    : null;

  const stackStatus = orderStacks.map((stack) => {
    const isReadyStack = stack.every(
      (stack) => stack.latestStatus === "pickingCompleted"
    );
    const isLockedStack = stack.every(
      (stack) => stack.isLockedFromRestacking === true
    );
    return { isReadyStack, isLockedStack };
  });

  return { stackStatus, mostUrgentStackId };
}

export default getStackStatus;
