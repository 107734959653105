import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useStaffingRedirectState } from "./StaffingRedirectState";

const StaffingRedirect: React.FC = () => {
  const location = useLocation();

  const state = useStaffingRedirectState();

  let title = "";
  switch (state.redirectRequest) {
    case "ADJUST_RIDER_SCHEDULE":
      title = "Adjusting rider schedule...";
      break;
    case "ADJUST_RIDER_SCHEDULE_DONE":
      title = "Adjust rider schedule - DONE!";
      break;
    case "SUBMIT_RIDER_SCHEDULE":
      title = "Submitting rider schedule to Planday...";
      break;
    case "SUBMIT_RIDER_SCHEDULE_DONE":
      title = "Submit rider schedule to Planday - DONE!";
      break;
    case "COMPLETED_PLEASE_CLOSE_TAB":
      title =
        "Completed staffing request already! Feel free to close this page";
      break;
    default:
    case "INVALID_INPUT":
      title = "Error - could not detect input parameters from browser URL";
      break;
  }

  useEffect(() => {
    state.setStateFromURL(location.search);
    if (state.loading) {
      switch (state.redirectRequest) {
        case "ADJUST_RIDER_SCHEDULE":
          state.createRiderSchedule();
          break;
        case "SUBMIT_RIDER_SCHEDULE":
          state.submitRiderSchedule();
          break;
        default:
          break;
      }
    }
  }, [state.loading]);

  return (
    <>
      <h1>{title}</h1>
      {state.messages.map((message, i) => (
        <p key={i}>{message}</p>
      ))}
    </>
  );
};

export default StaffingRedirect;
