import {
  SpilloverData,
  StaffingForecast,
} from "../../../data/StaffingForecast";

export function getChartTicks(
  data: StaffingForecast | SpilloverData[],
  name: string
) {
  const ticks = [];
  let max = 0;

  if (name === "riders") {
    const ridersData = data as StaffingForecast;
    const ridersDemand = ridersData.quarters.map((quart) => quart.riderDemand);
    const ridersProposed = ridersData.quarters.map(
      (quart) => quart.ridersProposed
    );
    const ridersPlanned = ridersData.quarters.map(
      (quart) => quart.ridersPlanned
    );
    max = Math.max(...ridersDemand, ...ridersProposed, ...ridersPlanned);
  }

  if (name === "orders") {
    const ordersData = data as StaffingForecast;
    const orders = ordersData.quarters.map((quart) => quart.orders);
    max = Math.max(...orders);
  }

  if (name === "spillover") {
    const spilloverData = data as SpilloverData[];
    const spillover = spilloverData.map((obj) => obj.spillover);
    max = Math.max(...spillover);
  }

  for (let i = 0; i <= Math.ceil(max); i++) {
    ticks.push(i);
  }
  return ticks;
}
