export const headerFontSize = {
  xs: 10,
  md: 16,
  lg: 24,
  xl: 32,
};

export const widgetHeaderFontSize = {
  xs: "18px",
  md: "12px",
  lg: "18px",
  xl: "42px",
};

export const fontWeight = 400;
export const boldFontWeight = 700;

export const paletteSize = { xs: 20, md: 32, xl: 56 };

export const orderCardHeight = { xs: 45, lg: 60, xl: 80 };

export const borderRadius = { xs: "3px", md: "4px", lg: "3px", xl: "8px" };

export const headerHeight = { xs: "70px", md: "60px", xl: "64px" };
export const headerPadding = { xs: 2, md: 5, xl: 9 };

export const listPadding = { xs: 2, md: 1, xl: 4 };

export const iconSize = { xs: 16, lg: 24, xl: 32 };

export const hourglassSize = { xs: 12, lg: 18, xl: 24 };
export const markerSize = { xs: "22px", md: "8px", lg: "16px", xl: "55px" };

export const listShadow = "0px 2px 14px rgba(175, 175, 175, 0.25)";

export const listBorderRadius = { xs: "9px", md: "6px", lg: "9px", xl: "20px" };

export const listBorder = "1px solid #EEEEEE";

export const poFontSize = { xs: "18px", md: "12px", lg: "18px", xl: "32px" };

export const darkGrey = "#4D505B";

export const yellow = "#FFEA79";
export const green = "#BDF077";
export const red = "#FFC9C9";
export const orange = "#FFD9B4";
export const black = "#1C1C1C";

export const cardHeight = (hasAssignee: boolean) =>
  hasAssignee
    ? { xs: "62px", md: "42px", lg: "60px", xl: "149px" }
    : { xs: "42px", md: "25px", lg: "42px", xl: "106px" };
