import React from "react";

import { Outlet, useLocation } from "react-router-dom";

import { Box, Toolbar } from "@mui/material";

import Header from "../components/Header";
import SideBar from "../components/SideBar";

export const Panel = () => {
  const location = useLocation();

  const backgroundColor =
    location.pathname === "/purchase-orders" ? "#E5E5E5" : undefined;

  return (
    <Box sx={{ display: "flex", height: "100vh", backgroundColor }}>
      <Header />
      {location.pathname !== "/purchase-orders" && <SideBar />}
      <Box component="main" sx={{ flexGrow: 1, p: 4.5 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};
