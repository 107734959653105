import React, { ReactNode } from "react";

import { Box } from "@mui/material";

import { boldFontWeight } from "../dashboard/styles";
import InCircle from "./InCircle";
import { gridFontSize } from "./styles";

const RiderGridHeader = (props: {
  color: string;
  icon: ReactNode;
  children: ReactNode;
}) => {
  return (
    <Box
      fontSize={gridFontSize}
      fontWeight={boldFontWeight}
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={1}
      paddingTop={1.5}
      paddingBottom={1.5}
      paddingLeft={2}
      sx={{ backgroundColor: "#FAF9F9" }}
    >
      <InCircle color={props.color}>{props.icon}</InCircle>
      {props.children}
    </Box>
  );
};

export default RiderGridHeader;
