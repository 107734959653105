import React from "react";

import { useLocation } from "react-router-dom";

const NoMatch: React.FC = () => {
  const location = useLocation();

  return (
    <>
      No match for <code>{location.pathname}</code>
    </>
  );
};

export default NoMatch;
