import React from "react";

import { Typography } from "@mui/material";

import { darkGrey } from "./styles";

const ListHeader = ({ name, length }: { name: string; length: number }) => (
  <>
    <Typography
      variant="h6"
      sx={{
        fontSize: { xs: "28px", md: "18px", lg: "26px", xl: "62px" },
        color: darkGrey,
        fontWeight: 700,
      }}
      component="div"
    >
      {name}
    </Typography>
    <Typography
      sx={{
        marginLeft: 1,
        color: darkGrey,
        fontSize: { xs: 16, xl: 36 },
      }}
    >
      ({length})
    </Typography>
  </>
);

export default ListHeader;
