import React from "react";

import { Box } from "@mui/material";

import Order from "../../../data/Order";
import OrderIcons from "./OrderIcons";
import OrderMinutes from "./OrderMinutes";
import OrderNumber from "./OrderNumber";
import NumberBox from "./NumberBox";

const OrderStatusBar = (props: {
  order: Order;
  isToRideStackingEnabled: boolean;
  advancedView: boolean;
}) => {
  const { advancedView, order } = props;
  const isDeliveredOrder = Boolean(order.fulfilledTimestamp);

  const boxText = advancedView
    ? `${order.itemsCount}/${order.bagsCount}`
    : order.bagsCount;

  const useBlueColor = advancedView && order.hasFrozenProduct;
  const boxColor = useBlueColor ? "#E9F4FE" : undefined;
  const textColor = useBlueColor ? "#005195" : undefined;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        sx={{
          minWidth: "50%",
          gap: { xs: 1, md: 1, lg: 2, lg2: 3 },
        }}
      >
        <OrderNumber {...props} />
        <Box display="flex" gap={1}>
          <OrderMinutes {...props} />
          <OrderIcons {...props} />
        </Box>
      </Box>
      {!isDeliveredOrder && (
        <Box display="flex" flexGrow={1} justifyContent="flex-end">
          <NumberBox
            number={boxText}
            boxColor={boxColor}
            textColor={textColor}
          />
        </Box>
      )}
    </Box>
  );
};

export default OrderStatusBar;
