import { Square } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

const GraphLineInfo = () => {
  const lines = [
    {
      title: "Rider Demand",
      color: "#6525c4",
    },
    {
      title: "Riders Proposed",
      color: "#000000",
    },
    {
      title: "Planned Riders",
      color: "#84be52",
    },
    {
      title: "Orders",
      color: "#fbc568",
    },
  ];
  return (
    <Box display="flex" gap={1} marginY={2}>
      {lines.map((line, index) => (
        <Box display="flex" alignItems="center" key={index}>
          <Square sx={{ color: line.color, height: "20px", width: "20px" }} />
          <Typography fontSize="12px" color="#565656">
            {line.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default GraphLineInfo;
