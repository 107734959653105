import React from "react";
import { Box, Typography } from "@mui/material";
import { boldFontWeight, fontWeight } from "../styles";
import Order from "../../../data/Order";

const Assignee = (props: {
  assignee?: string;
  suggestedRider?: string;
  picker?: string;
  rider?: string;
  order?: Order;
}) => {
  const sx = {
    fontSize: { md: "10px", lg: "15px", xl: "36px" },
    fontWeight: fontWeight,
    color: "#7D7D7D",
    display: { xs: "none", md: "flex" },
    lineHeight: "120%",
  };
  return (
    <Box
      display="flex"
      paddingTop={
        props.assignee || props.suggestedRider || props.picker ? "0px" : "6px"
      }
    >
      {props.suggestedRider && !props.picker && (
        <Box display="flex" gap="4px">
          <Typography component="span" sx={sx}>
            -
          </Typography>
          <Typography component="span" sx={sx}>
            /
          </Typography>
          <Typography component="span" sx={sx}>
            {props.suggestedRider.toUpperCase()}
          </Typography>
        </Box>
      )}
      {props.picker && !props.suggestedRider && (
        <Box display="flex" gap="4px">
          <Typography component="span" sx={sx}>
            {props.picker.toUpperCase()}
          </Typography>
        </Box>
      )}
      {props.suggestedRider && props.picker && (
        <Box display="flex" gap="4px">
          <Typography
            component="span"
            sx={sx}
            style={
              props.order?.latestStatus === "pickingStarted"
                ? { fontWeight: boldFontWeight }
                : { fontWeight: fontWeight }
            }
          >
            {props.picker.toUpperCase()}
          </Typography>
          <Typography component="span" sx={sx}>
            /
          </Typography>
          <Typography
            component="span"
            sx={sx}
            style={
              props.order?.latestStatus === "pickingCompleted"
                ? { fontWeight: boldFontWeight }
                : { fontWeight: fontWeight }
            }
          >
            {props.suggestedRider.toUpperCase()}
          </Typography>
        </Box>
      )}

      {props.assignee && (
        <Box>
          <Typography sx={sx}>{props.assignee.toUpperCase()}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Assignee;
