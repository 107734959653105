export const performanceCardStyle = {
  backgroundColor: "#FFF",
  width: "100%",
  display: "flex",
  borderRadius: 2,
  border: "1px solid #EEEEEE",
  paddingTop: 2,
  paddingLeft: 3,
  paddingRight: 3,
  paddingBottom: 2,
  flexDirection: "column",
  marginBottom: 2,
  boxShadow: "none",
};

export const shiftsColors = {
  morning: "#FFF9C8",
  evening: "#32468C",
};

export const shiftsTextColors = {
  morning: "#000000",
  evening: "#FFFFFF",
};

export const gridFontSize = { xs: 16, xl: 30 };
