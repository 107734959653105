import { createState, useState } from "@hookstate/core";

import { AdminActions, useAuthState } from "../../authentication/AuthState";
import {
  StaffingStore,
  StaffingStoreAndDates,
} from "../../data/StaffingForecast";

const initialState: StaffingStoreAndDates = {
  stores: [],
  dates: [],
  isLoading: false,
};

const staffingStoresAndDatesState = createState(initialState);

export const useStaffingStoresAndDates = () => {
  const state = useState(staffingStoresAndDatesState);
  const authState = useAuthState();

  return {
    get stores() {
      return state.stores.get();
    },
    get dates() {
      return state.dates.get();
    },
    setStores(stores: StaffingStore[]) {
      return state.stores.set(stores);
    },
    setDates(dates: string[]) {
      return state.dates.set(dates);
    },
    async fetchStaffingStoresAndDates() {
      state.isLoading.set(true);
      const storesAndDates = await authState.authenticatedRequest(
        AdminActions.GET_STAFFING_STORES_AND_DATES
      );

      const response = storesAndDates.data.result as StaffingStoreAndDates;
      state.set(response);
      state.isLoading.set(false);
    },
    get isLoading() {
      return state.isLoading.get();
    },
  };
};
