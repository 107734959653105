import moment from "moment";
import Order from "../../../data/Order";
import { AvailableRider } from "../../../data/Riders";
import getSecondsAvailable from "./getSecondsAvailable";
import getStackStatus from "./getStackStatus";
import { concatRidersName } from "./ridersFormatting";

const CHECKING_TIME_SEC = 60;

export enum TrafficLightStatuses {
  Go = "go",
  GetReady = "getReady",
  Wait = "wait",
  Checking = "checking",
}

export function getTrafficLightStatus(
  riders: AvailableRider[],
  rider: string,
  orders: Order[]
) {
  const availableRiders = riders
    ?.filter((rider) => rider.status === "available")
    .sort(
      (a, b) =>
        (moment(a.availableSinceTimestamp).valueOf() || 0) -
        (moment(b.availableSinceTimestamp).valueOf() || 0)
    );
  if (!orders) return;

  const stacksWithSuggestedRider = orders.filter(
    (order) =>
      order.orderStackId !== null &&
      order.suggestedRider?.name.toLocaleUpperCase() === rider &&
      (order.latestStatus === "orderCreated" ||
        order.latestStatus === "pickingStarted" ||
        order.latestStatus === "pickingCompleted")
  );

  const availableRider = availableRiders?.find(
    (availableRider) =>
      concatRidersName(availableRider.firstName, availableRider.lastName) ===
      rider
  );

  if (!availableRider) return;

  const secondsAvailable = getSecondsAvailable(availableRider);

  const { stackStatus } = getStackStatus(
    stacksWithSuggestedRider,
    availableRider
  );

  if (stackStatus.some((stack) => stack.isLockedStack && stack.isReadyStack)) {
    return TrafficLightStatuses.Go;
  }
  if (stackStatus.some((stack) => stack.isLockedStack && !stack.isReadyStack)) {
    return TrafficLightStatuses.GetReady;
  }
  if (
    (secondsAvailable > CHECKING_TIME_SEC &&
      (stacksWithSuggestedRider.length === 0 ||
        stackStatus.every((stack) => !stack.isLockedStack))) ||
    orders
      .filter((order) => order.latestStatus === "pickingCompleted")
      .every((order) => order.isLockedFromRestacking)
  ) {
    return TrafficLightStatuses.Wait;
  }
  if (
    stacksWithSuggestedRider.length === 0 ||
    stackStatus.every((stack) => !stack.isLockedStack)
  ) {
    return TrafficLightStatuses.Checking;
  }

  return null;
}
