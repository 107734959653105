import React, { useEffect } from "react";

import { Navigate, useLocation } from "react-router-dom";

import pages from "../pages/Pages";
import { useAuthState } from "./AuthState";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const authState = useAuthState();
  const location = useLocation();

  useEffect(() => {
    async function validateToken() {
      await authState.validateToken();
    }
    validateToken();
  }, [location]);

  if (authState.status !== "authorized") {
    const to = `/login${location.search}`;
    return <Navigate to={to} state={{ from: location.pathname }} replace />;
  }

  const pageAction = pages.find(
    (page) => page.path === location.pathname
  )?.action;

  if (pageAction && !authState.userHasPermission(pageAction)) {
    return <Navigate to="/" state={{ from: location.pathname }} replace />;
  }

  return children;
}
