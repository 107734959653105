import { Grid, Typography } from "@mui/material";
import React from "react";

type Props = {
  storeInfo: {
    name: string;
    day: string;
    date: string;
  };
};

const StoreAndDate = (props: Props) => {
  const { storeInfo } = props;
  return (
    <Grid display="flex" flexDirection="column" alignItems="center">
      <Typography sx={{ fontWeight: "bold" }}>Showing:</Typography>
      <Typography>{storeInfo.name}</Typography>
      <Typography>{storeInfo.day}</Typography>
      <Typography>{storeInfo.date}</Typography>
    </Grid>
  );
};

export default StoreAndDate;
