import { Box, Typography } from "@mui/material";
import React from "react";

const OverviewContent = () => {
  const sx = {
    fontSize: ".65rem",
  };
  const sxHeader = {
    fontSize: ".7rem",
    color: "#773fca",
    marginBottom: "0",
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="4px"
      maxWidth={600}
      maxHeight={600}
      sx={{ paddingX: "1rem" }}
    >
      <Typography component="h1" sx={{ fontWeight: "bold" }}>
        Staffing Forecast Overview
      </Typography>
      <Box>
        <Typography sx={sx}>
          This view shows the staffing prediction for every day. Everything is
          updated in{" "}
          <Typography
            component="span"
            sx={{ fontSize: ".65rem", fontStyle: "italic" }}
          >
            real time
          </Typography>{" "}
          except for{" "}
          <Typography
            component="span"
            sx={{ color: "#84be52", fontSize: ".65rem" }}
          >
            Planned Riders
          </Typography>{" "}
          which are updated every hour.
        </Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          Generate new shifts:
        </Typography>
        <Typography sx={sx}>
          This will generate a new staffing suggestion{" "}
          <Typography
            component="span"
            sx={{ fontSize: ".65rem", fontWeight: "bold" }}
          >
            Riders Proposed{" "}
          </Typography>
          based on
          <Typography
            component="span"
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: ".65rem",
              paddingLeft: "10px",
            }}
          >
            -Order factor <br />
            -pUTR factor <br />
            -Absence factor <br />
          </Typography>
          When generating a new proposition, one can expect it to be done in
          aprox. 1 minute.
        </Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          Submit shifts:
        </Typography>
        <Typography sx={sx}>
          This will create shifts in Planday that correspond to the line{" "}
          <Typography
            component="span"
            sx={{ fontSize: ".65rem", fontWeight: "bold" }}
          >
            Riders Proposed
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          Order factor:
        </Typography>
        <Typography sx={sx}>
          The amount of orders we are considering from the model (100% = all of
          the predicted orders)
        </Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          Exp. Absence:
        </Typography>
        <Typography sx={sx}>Absence hours / total proposed hours</Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          Absence factor:
        </Typography>
        <Typography sx={sx}>
          The amount of orders we are considering from the model (100% = all of
          the predicted orders)
        </Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          Avg pUTR:
        </Typography>
        <Typography sx={sx}>
          The pUTR that the model has predicted over the day
        </Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          pUTR factor:
        </Typography>
        <Typography sx={sx}>
          The pUTR that the model has calculated (100% = 100% of the predicted
          pUTR)
        </Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          UTR:
        </Typography>
        <Typography sx={sx}>Total number of orders / total hours</Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          Total orders:
        </Typography>
        <Typography sx={sx}>
          The total number of orders predicted for the day
        </Typography>
      </Box>
      <Box>
        <Typography component="h6" sx={sxHeader}>
          Total hours:
        </Typography>
        <Typography sx={sx}>
          The total number of hours predicted for the day
        </Typography>
      </Box>
      <Box sx={{ paddingBottom: "1rem" }}>
        <Typography component="h6" sx={sxHeader}>
          Planned spillover:
        </Typography>
        <Typography sx={sx}>
          Forecasted order volume - planned riders * UTR. Example: If spillover
          = 1 then we expect to not be able to deliver 1 order that quarter. If
          spillover = -1 then we expect to have capacity to deliver 1 order more
          than we expect to receive that quarter.
        </Typography>
      </Box>
    </Box>
  );
};

export default OverviewContent;
