import * as React from "react";
import Popover from "@mui/material/Popover";
import InfoIcon from "@mui/icons-material/Info";
import OverviewContent from "./OverviewContent";

const OverviewModal = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (event: any) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div
        aria-describedby={id}
        onClick={handleOpen}
        style={{ cursor: "pointer" }}
      >
        <InfoIcon sx={{ fontSize: "2rem" }} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ marginTop: "2rem" }}
      >
        <OverviewContent />
      </Popover>
    </div>
  );
};

export default OverviewModal;
