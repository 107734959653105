import React from "react";

import moment from "moment";
import ReactDOM from "react-dom";

import "@hookstate/devtools";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import App from "./App";
import { makeBuildInfoAvailable } from "./buildInfo";

moment.updateLocale("en", { week: { dow: 1 } });

makeBuildInfoAvailable();

ReactDOM.render(<App />, document.getElementById("app"));
