import React from "react";

import { Box } from "@mui/material";

import { OrdersGraphLabel } from "./OrdersGraphLabel";

export function OrdersGraphHeader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
      }}
    >
      <OrdersGraphLabel
        text="Order forecast"
        color="rgba(44, 51, 58, 1)"
        icon="planned"
        textFontSize="20px"
        iconfontSize="23px"
        fontWeight="700"
        gap="2px"
      />
      <Box display="flex" flexDirection="row" gap="20px">
        <OrdersGraphLabel text="Unplanned" color="rgba(172, 218, 191, 1)" />
        <OrdersGraphLabel text="Planned" color="rgba(21, 118, 61, 1)" />
        <OrdersGraphLabel
          text="Upcoming planned"
          color="rgba(167, 179, 190, 1)"
        />
        <OrdersGraphLabel
          text="Forecast"
          color="rgba(87, 102, 117, 1)"
          icon="line"
        />
      </Box>
    </Box>
  );
}
