import React from "react";

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import { Box, useMediaQuery } from "@mui/material";

import { usePerformanceDashboardState } from "../../pages/PerformanceDashboard/PerformanceDashboardState";
import { boldFontWeight } from "../dashboard/styles";
import DashboardCard from "./DashboardCard";

const ChartLegend = ({ data }: { data: { name: string; color: string }[] }) => {
  const isXXl = useMediaQuery("(min-width:3200px)");

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="baseline"
      sx={{ marginLeft: isXXl ? 0 : 2, marginTop: isXXl ? 0 : "50px" }}
      width={isXXl ? 200 : undefined}
    >
      {data.map((entry, index) => (
        <div
          key={`legend-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "1rem",
              height: "1rem",
              marginRight: "0.5rem",
              backgroundColor: entry.color,
              borderRadius: "50%",
            }}
          />
          <span>{entry.name}</span>
        </div>
      ))}
    </Box>
  );
};

const ChartLabel = (props: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  fontSize: number;
}) => {
  const RADIAN = Math.PI / 180;

  const { cx, cy, midAngle, innerRadius, outerRadius, percent, fontSize } =
    props;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    (percent && (
      <text
        x={x}
        y={y}
        fill="#333"
        textAnchor="center"
        transform={`translate(${-fontSize}, 0)`}
        dominantBaseline="central"
        fontWeight={boldFontWeight}
        fontSize={fontSize}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )) ||
    null
  );
};

const OrdersPieChart = ({
  data,
}: {
  data: { name: string; value: number; color: string }[];
}) => {
  const isXXl = useMediaQuery("(min-width:3200px)");

  const width = isXXl ? 500 : 200;
  const outerRadius = isXXl ? 210 : 100;
  const innerRadius = isXXl ? 100 : 60;

  const totalOrdersCount = data.reduce((acc, entry) => acc + entry.value, 0);

  const cx = isXXl ? "50%" : "50%";

  return (
    <ResponsiveContainer width={width} height={width}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          cx={cx}
          cy="50%"
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          labelLine={false}
          label={ChartLabel}
          fontSize={isXXl ? 38 : 12}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <text
          x={cx}
          y="50%"
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: isXXl ? 25 : 15,
            fill: "#4b4e57",
          }}
        >
          Delivered orders
        </text>
        <text
          x={cx}
          y="50%"
          transform={`translate(0, ${isXXl ? 40 : 28})`}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: isXXl ? 40 : 28,
            fill: "#243465",
            fontWeight: "bold",
          }}
        >
          {totalOrdersCount}
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

const OrdersPieChartGrid = ({
  data,
}: {
  data: { name: string; value: number; color: string }[];
}) => {
  const isXXl = useMediaQuery("(min-width:3600px)");
  return (
    <Box display="flex" flexDirection={isXXl ? "row" : "column"}>
      <OrdersPieChart data={data} />
      <ChartLegend data={data} />
    </Box>
  );
};

export const Chart = () => {
  const { orders } = usePerformanceDashboardState();

  const inTimeOrdersCount =
    orders?.performance.filter((order) => order.performance === "inTime")
      .length || 0;
  const almostInTimeOrdersCount =
    orders?.performance.filter((order) => order.performance === "almostInTime")
      .length || 0;
  const notInTimeOrdersCount =
    orders?.performance.filter((order) => order.performance === "notInTime")
      .length || 0;

  const data = [
    { name: "Not in time", value: notInTimeOrdersCount, color: "#FFC9C9" },
    {
      name: "Almost in time",
      value: almostInTimeOrdersCount,
      color: "#FFEA79",
    },
    { name: "On target", value: inTimeOrdersCount, color: "#BDF077" },
  ];

  return (
    <Box display="flex" flexDirection="column">
      <DashboardCard>
        <OrdersPieChartGrid data={data} />
      </DashboardCard>
    </Box>
  );
};
