import React from "react";

import { Box, Typography } from "@mui/material";

import { boldFontWeight } from "./styles";

const TimeBox = (props: {
  minutes: number;
  isPlannedOrder?: boolean;
  isPreRideEstimate?: boolean;
}) => {
  let boxColor: string | undefined;
  let textColor: string | undefined;
  if (props.minutes !== undefined) {
    if (props.isPreRideEstimate) {
      switch (true) {
        case props.minutes <= 30:
          boxColor = "#EBFEEE";
          textColor = "#45BC58";
          break;
        case props.minutes <= 40:
          boxColor = "#FFF7EC";
          textColor = "#EF9B20";
          break;
        default:
          boxColor = "#FFF2F2";
          textColor = "#F03737";
      }
    } else if (props.isPlannedOrder) {
      switch (true) {
        case props.minutes >= 0:
          boxColor = "#EBFEEE";
          textColor = "#45BC58";
          break;
        default:
          boxColor = "#FFF2F2";
          textColor = "#F03737";
      }
    } else {
      switch (true) {
        case props.minutes >= 0:
          boxColor = "#EBFEEE";
          textColor = "#45BC58";
          break;
        case props.minutes >= -5:
          boxColor = "#FFF7EC";
          textColor = "#EF9B20";
          break;
        default:
          boxColor = "#FFF2F2";
          textColor = "#F03737";
      }
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: boxColor || null,
        height: { xs: 28, md: 24, lg: 28, xl: 48 },
        borderRadius: "51px",
        textAlign: "middle",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingRight: {
          xs: "10px",
          md: "6px",
          lg: "8px",
          xl: "10px",
        },
        paddingLeft: {
          xs: "10px",
          md: "6px",
          lg: "8px",
          xl: "10px",
        },
      }}
    >
      <Typography
        sx={{
          color: textColor || null,
          fontSize: { xs: 12, md: 10, lg2: 14, xl: 24 },
          fontWeight: boldFontWeight,
        }}
      >
        {props.minutes} m
      </Typography>
    </Box>
  );
};

export default TimeBox;
