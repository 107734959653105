import { Box, Typography } from "@mui/material";
import React from "react";
import { StaffingForecast } from "../../data/StaffingForecast";

type Props = {
  forecastData: StaffingForecast;
};

const KPIStrip = (props: Props) => {
  const { forecastData } = props;

  function trimDecimals(value: number | null, decimals = 2) {
    if (typeof value !== "number") return;

    return value.toFixed(decimals);
  }

  function getPercentage(value: number | null) {
    if (typeof value !== "number") return;

    return (value * 100).toFixed(0);
  }

  return (
    forecastData && (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        marginTop={2}
      >
        <Box>
          <Typography>Order Factor</Typography>
          <Typography color="#773fca">
            {getPercentage(forecastData.orderFactor)}%
          </Typography>
        </Box>
        <Box>
          <Typography>Exp. Absence</Typography>
          <Typography color="#773fca">
            {getPercentage(forecastData.expectedAbsence)}%
          </Typography>
        </Box>
        <Box>
          <Typography>Absence Factor</Typography>
          <Typography color="#773fca">
            {getPercentage(forecastData.absenceFactor)}%
          </Typography>
        </Box>
        <Box>
          <Typography>pUTR Factor</Typography>
          <Typography color="#773fca">
            {getPercentage(forecastData.pUTRFactor)}%
          </Typography>
        </Box>
        <Box>
          <Typography>Avg. pUTR</Typography>
          <Typography color="#773fca">
            {trimDecimals(forecastData.averagePUTR)}
          </Typography>
        </Box>

        <Box>
          <Typography>UTR</Typography>
          {forecastData.totalOrders &&
            forecastData.totalHours &&
            forecastData.expectedAbsence !== null && (
              <Typography color="#773fca">
                {trimDecimals(
                  forecastData.totalOrders /
                    (forecastData.totalHours *
                      (1 - forecastData.expectedAbsence))
                )}
              </Typography>
            )}
        </Box>
        <Box>
          <Typography>Total Orders</Typography>
          <Typography color="#773fca">
            {Math.floor(Number(forecastData.totalOrders)).toFixed(0)}
          </Typography>
        </Box>
        <Box>
          <Typography>Total Hours</Typography>
          <Typography color="#773fca">
            {trimDecimals(forecastData.totalHours)}
          </Typography>
        </Box>
        <Box>
          <Typography>Latest Update</Typography>
          <Typography color="#773fca">
            {new Date(
              String(forecastData.latestUpdateTimestamp)
            ).toLocaleString()}
          </Typography>
        </Box>
      </Box>
    )
  );
};

export default KPIStrip;
