import React from "react";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { iconSize } from "./styles";

const FailedDeliveryIcon = () => (
  <CancelOutlinedIcon
    sx={{
      color: "#f04141",
      fontSize: iconSize,
    }}
  />
);

export default FailedDeliveryIcon;
