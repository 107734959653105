import { FC } from "react";

import {
  DirectionsBike,
  Equalizer,
  Shop,
  Summarize,
  SvgIconComponent,
} from "@mui/icons-material";

import { AdminActions } from "../authentication/AuthState";
import NavigateToDashboard from "./Dashboard/NavigateToDashboard";
import NavigateToPerformanceDashboard from "./PerformanceDashboard/NavigateToPerformanceDashboard";
import PurchaseOrders from "./PurchaseOrders/PurchaseOrders";
import NavigateToStaffingForecast from "./StaffingForecast/NavigateToStaffingForecast";

interface Page {
  name: string;
  icon: SvgIconComponent | (() => JSX.Element);
  path: string;
  enableForProduction: boolean;
  action: AdminActions[];
  element: (() => JSX.Element) | FC;
}

const pages: Page[] = [
  {
    name: "Purchase orders",
    icon: Shop,
    path: "/purchase-orders",
    enableForProduction: true,
    action: [AdminActions.GET_PURCHASE_ORDERS, AdminActions.GET_PURCHASE_ORDER],
    element: PurchaseOrders,
  },
  {
    name: "Dashboard",
    icon: Summarize,
    path: "/dashboard",
    enableForProduction: true,
    action: [AdminActions.GET_DASHBOARD],
    element: NavigateToDashboard,
  },
  {
    name: "Performance",
    icon: DirectionsBike,
    path: "/performance",
    enableForProduction: true,
    action: [AdminActions.GET_PERFORMANCE_DASHBOARD],
    element: NavigateToPerformanceDashboard,
  },
  {
    name: "Staffing Forecast",
    icon: Equalizer,
    path: "/staffing-forecast",
    enableForProduction: true,
    action: [
      AdminActions.GET_STAFFING_FORECAST,
      AdminActions.GET_STAFFING_STORES_AND_DATES,
    ],
    element: NavigateToStaffingForecast,
  },
];

export default pages;
