import React from "react";

import { List, ListItem } from "@mui/material";

import PurchaseOrder from "../../data/PurchaseOrder";
import PurchaseOrderCard from "./PurchaseOrderCard";
import SubListHeader from "./SubListHeader";

const PurchaseOrdersList = (props: { purchaseOrders: PurchaseOrder[] }) => {
  return (
    <List
      sx={{
        maxHeight: "40%",
        minHeight: "15%",
        overflowY: "scroll",
        overflowX: "hidden",
        flexShrink: 10,
      }}
    >
      <ListItem
        sx={{
          display: "flex",
          height: { xs: 36, md: 23, lg: 33, xl: 80 },
          paddingLeft: 0,
          marginBottom: "5px",
        }}
      >
        <SubListHeader name="📦 Purchase orders" />
      </ListItem>
      {props.purchaseOrders.map((order) => (
        <ListItem
          key={order.number}
          sx={{
            padding: 0,
            paddingBottom: { xs: "5px", md: "3px", lg: "5px", xl: "12px" },
          }}
        >
          <PurchaseOrderCard purchaseOrder={order} />
        </ListItem>
      ))}
    </List>
  );
};

export default PurchaseOrdersList;
