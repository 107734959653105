import { createState, useState } from "@hookstate/core";

import config from "../config";
import GetPurchaseOrderRequest from "../pages/PurchaseOrders/GetPurchaseOrderRequest";
import GetPurchaseOrdersRequest from "../pages/PurchaseOrders/GetPurchaseOrdersRequest";
import {
  CreateRiderSchedulePayload,
  SubmitRiderSchedulePayload,
} from "../pages/StaffingRedirect/StaffingRedirectRequests";
import ChangePurchaseOrderStatusRequest from "../pages/PurchaseOrders/ChangePurchaseOrderStatusRequest";
import {
  StaffingDates,
  StaffingForecastRequest,
  StaffingForecastRequests,
} from "../data/StaffingForecast";

declare global {
  const google: typeof import("google-one-tap");
}

export enum AdminActions {
  GET_USER = "GET_USER",
  GET_DASHBOARD = "GET_DASHBOARD",
  GET_PURCHASE_ORDERS = "GET_PURCHASE_ORDERS",
  GET_PURCHASE_ORDER = "GET_PURCHASE_ORDER",
  CREATE_RIDER_SCHEDULE = "CREATE_RIDER_SCHEDULE",
  SUBMIT_RIDER_SCHEDULE = "SUBMIT_RIDER_SCHEDULE",
  GET_PERFORMANCE_DASHBOARD = "GET_PERFORMANCE_DASHBOARD",
  GET_STORES = "GET_STORES",
  CHANGE_PURCHASE_ORDER_STATUS = "CHANGE_PURCHASE_ORDER_STATUS",
  GET_STAFFING_FORECAST = "GET_STAFFING_FORECAST",
  GET_STAFFING_STORES_AND_DATES = "GET_STAFFING_STORES_AND_DATES",
}

interface AuthState {
  status: "unauthenticated" | "unauthorized" | "authorized";
  authorizedCount: number;
  token: string | null;
  email: string | null;
  showNotification: boolean;
  notification: {
    title: string;
    description: string;
  };
  actions: AdminActions[];
}

export interface GetDashboardRequest {
  item: string;
  storeId: number;
}

export interface GetPerformanceDashboardRequest {
  storeId: number;
}

function unauthenticated(): AuthState {
  return {
    status: "unauthenticated",
    authorizedCount: 0,
    token: null,
    email: null,
    showNotification: false,
    notification: {
      title: "",
      description: "",
    },
    actions: [],
  };
}

const createAuthState = () => {
  const state = createState(unauthenticated());

  const localStorageAuth = localStorage.getItem("auth") || "";
  if (localStorageAuth) {
    try {
      const { token, email, actions } = JSON.parse(localStorageAuth);

      if (token && email && actions) {
        state.merge({ token, email, actions, status: "authorized" });
      }
    } catch (error) {
      console.log(error);
    }
  }
  return state;
};

const authState = createAuthState();

export function useAuthState() {
  const state = useState(authState);

  const status = () => state.status.get();

  return {
    setLocalstorage() {
      const token = this.token;
      const email = this.email;
      const actions = this.actions;
      localStorage.setItem("auth", JSON.stringify({ token, email, actions }));
    },
    authorize(token: string, email: string, actions: AdminActions[]) {
      state.merge({
        status: "authorized",
        authorizedCount: state.authorizedCount.get() + 1,
        token,
        email,
        actions,
      });
      this.setLocalstorage();
    },
    unauthorize(token: string) {
      state.merge({
        status: "unauthorized",
        token,
      });
      localStorage.removeItem("auth");
    },
    get status() {
      return status();
    },
    get actions() {
      return state.actions.get();
    },
    get showNotification() {
      return state.showNotification.get();
    },
    hideNotification() {
      state.showNotification.set(false);
    },
    get notification() {
      return state.notification.get();
    },
    get authorizedCount() {
      return state.authorizedCount.get();
    },
    get token(): string {
      if (status() !== "authorized") {
        throw new Error("Not authorized!");
      }
      const token = state.token.get();
      if (!token) {
        throw new Error("Missing token!");
      }

      return token;
    },
    get email(): string {
      if (status() !== "authorized") {
        throw new Error("Not authorized!");
      }

      const email = state.email.get();
      if (!email) {
        throw new Error("Missing email!");
      }

      return email;
    },
    logout() {
      if (state.status.get() === "unauthenticated") {
        throw new Error("Not logged in!");
      }
      google.accounts.id.disableAutoSelect();
      state.set(unauthenticated());
      localStorage.removeItem("auth");
    },
    forceLogout() {
      google.accounts.id.disableAutoSelect();
      state.set({
        ...unauthenticated(),
        showNotification: true,
        notification: {
          title: "Expired token",
          description: "Please sign in and try again",
        },
      });
      localStorage.removeItem("auth");
    },
    async anonymousRequest(
      action: string,
      payload: GetDashboardRequest | GetPerformanceDashboardRequest | null
    ): Promise<{ status: number; data: any }> {
      const requestData = {
        data: {
          action,
          payload,
        },
      };

      const response = await fetch(config.apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const status = response.status;
      const data = await response.json();

      return { status, data };
    },
    async authenticatedRequest(
      action: string,
      payload?:
        | GetPurchaseOrdersRequest
        | GetPurchaseOrderRequest
        | CreateRiderSchedulePayload
        | SubmitRiderSchedulePayload
        | ChangePurchaseOrderStatusRequest
        | StaffingForecastRequest
        | StaffingForecastRequests
        | StaffingDates
    ): Promise<{ status: number; invalidToken: boolean; data: any }> {
      const requestData = {
        data: {
          token: this.token,
          action,
          payload,
        },
      };

      const response = await fetch(config.apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const status = response.status;
      const data = await response.json();
      const invalidToken =
        data?.error?.message.toLowerCase() === "invalid user";

      if (invalidToken) {
        this.forceLogout();
      }

      return { status, invalidToken, data };
    },
    async validateToken() {
      if (status() !== "authorized") {
        return;
      }

      const data = {
        data: {
          token: this.token,
          action: "AUTH",
        },
      };

      const response = await fetch(config.apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok && status() === "authorized") {
        this.forceLogout();
      }
    },
    userHasPermission(actions: AdminActions[]) {
      const allowedActions = state.actions
        .get()
        .filter((allowedAction) => actions.includes(allowedAction));

      return config.isDev ? true : allowedActions.length > 0;
    },
  };
}

export type UseAuthState = ReturnType<typeof useAuthState>;
