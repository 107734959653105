import React from "react";

import DateRangeIcon from "@mui/icons-material/DateRangeRounded";

import { iconSize } from "./styles";

const PlannedDeliveryIcon = () => (
  <DateRangeIcon
    sx={{
      color: "rgba(87, 102, 117, 1)",
      fontSize: iconSize,
    }}
  />
);

export default PlannedDeliveryIcon;
