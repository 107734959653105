import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, Typography } from "@mui/material";

type Props = { color: string; status: string };

const TrafficLight = (props: Props) => {
  const { color, status } = props;
  return (
    <Box sx={{ width: "40%" }} display="flex" alignItems="center" gap="4px">
      <CircleIcon
        sx={{
          color: { color },
          fontSize: { xs: 18, xl: 48 },
        }}
      />
      <Typography
        sx={{
          fontSize: { xs: 14, lg2: 18, xl: 48 },
          fontWeight: "bold",
        }}
      >
        {status}
      </Typography>
    </Box>
  );
};

export default TrafficLight;
