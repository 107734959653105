import OrdersGraphData from "../../../data/OrdersGraphData";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: 12,
          weight: "700",
          family: "Inter",
        },
      },
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        font: {
          size: 16,
          weight: "600",
          family: "Inter",
        },
      },
      stacked: true,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
    tension: 0,
  },
  maintainAspectRatio: false,
};

export const defaultOrdersGraphData: OrdersGraphData = {
  unplanned: [] as number[],
  planned: [] as number[],
  upcomingPlanned: [] as number[],
  forecast: [] as number[],
};
