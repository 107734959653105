import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { usePurchaseOrdersState } from "../../pages/PurchaseOrders/PurchaseOrdersState";

const NoMatch = () => {
  const { resetFilter } = usePurchaseOrdersState();

  return (
    <Box display="flex" flexDirection="column">
      <Typography>No orders matching the filter</Typography>
      <Button
        variant="outlined"
        onClick={resetFilter}
        sx={{ marginLeft: "20%", marginRight: "20%" }}
      >
        Reset
      </Button>
    </Box>
  );
};

export default NoMatch;
