import { Box, List, Typography } from "@mui/material";
import React from "react";
import {
  boldFontWeight,
  borderRadius,
  darkGrey,
  listBorder,
  listBorderRadius,
  listPadding,
  widgetHeaderFontSize,
} from "./styles";

import {
  concatRidersName,
  concatVehicleType,
} from "./helpers/ridersFormatting";
import TrafficLight from "./TrafficLight";

import { TrafficLightStatuses } from "./helpers/getTrafficLightStatus";
import { AvailableRider } from "../../data/Riders";

type Props = {
  availableRiders: AvailableRider[] | undefined;
};

const RidersWidget = (props: Props) => {
  const availableRiders = props.availableRiders;

  const sx = {
    width: "100%",
    background: "#ffffff",
    border: 1,
    borderColor: "#E9E9E9",
    borderRadius: borderRadius,
    padding: 0,
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    paddingX: "6px",
    paddingY: "4px",
  };
  return (
    <List
      sx={{
        padding: listPadding,
        paddingBottom: 0,
        backgroundColor: "background.default",
        borderRadius: listBorderRadius,
        border: listBorder,
        overflowY: { xs: "auto", md: "scroll" },
        overflowX: "hidden",
        flexShrink: 0,
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        paddingBottom={
          availableRiders === undefined || availableRiders.length <= 0
            ? "0px"
            : "8px"
        }
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: widgetHeaderFontSize,
            fontWeight: boldFontWeight,
            color: darkGrey,
            margin: "auto 0",
          }}
        >
          🏠 Available riders
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "10px", lg: "15px", xl: "36px" },
            color: darkGrey,
            margin: "auto 0",
          }}
        >
          ({availableRiders?.length ?? 0})
        </Typography>
      </Box>
      {availableRiders && (
        <Box display="flex" flexDirection="column">
          {availableRiders.map((rider, index) => (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              key={index}
              sx={sx}
            >
              <Box display="flex" alignItems="center" width="50%">
                <Typography
                  sx={{
                    fontSize: { xs: 14, lg2: 18, xl: 24 },
                    fontWeight: boldFontWeight,
                    width: "60%",
                  }}
                >
                  {concatRidersName(rider.firstName, rider.lastName)}
                </Typography>
                <Typography
                  key={index}
                  sx={{
                    fontSize: { xs: 12, lg2: 18, xl: 24 },
                    color: darkGrey,
                    width: "40%",
                  }}
                >
                  {concatVehicleType(rider.vehicleType)}
                </Typography>
              </Box>

              {availableRiderStatus(
                concatRidersName(rider.firstName, rider.lastName),
                availableRiders
              )}
            </Box>
          ))}
        </Box>
      )}
    </List>
  );
};

export function availableRiderStatus(
  rider: string,
  availableRiders: AvailableRider[]
) {
  const availableRider = availableRiders?.find(
    (availableRider) =>
      concatRidersName(availableRider.firstName, availableRider.lastName) ===
      rider
  );

  if (!availableRider) return;

  if (availableRider.trafficLightStatus === TrafficLightStatuses.Go) {
    return <TrafficLight color="#50C055" status="Go!" />;
  }
  if (availableRider.trafficLightStatus === TrafficLightStatuses.GetReady) {
    return <TrafficLight color="#FF9900" status="Get ready" />;
  }
  if (availableRider.trafficLightStatus === TrafficLightStatuses.Wait) {
    return <TrafficLight color="#b2b2b2" status="Wait" />;
  }
  if (availableRider.trafficLightStatus === TrafficLightStatuses.Checking) {
    return <TrafficLight color="#ffea79" status="Checking..." />;
  }

  return null;
}

export default RidersWidget;
