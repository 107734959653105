import React from "react";

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import logo from "../../assets/kavall_dashboard_logo.png";

const Logo = () => (
  <Box
    component={Link}
    to="/"
    sx={{
      height: { xs: "19px", md: "30px", xl: "47px" },
      textDecoration: "none",
      display: "flex",
    }}
  >
    <img src={logo} alt="Kavall-logo" />
  </Box>
);

export default Logo;
