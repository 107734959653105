import React from "react";

import { Box } from "@mui/material";

import { Chart } from "../../components/performance-dashboard/Chart";
import Info from "../../components/performance-dashboard/Info";
import PerformanceDashboardHeader from "../../components/performance-dashboard/PerformanceDashboardHeader";
import Pickers from "../../components/performance-dashboard/Pickers";
import Riders from "../../components/performance-dashboard/Riders";
import DashboardLayout from "../../layouts/DashboardLayout";
import { usePerformanceDashboardState } from "./PerformanceDashboardState";

const PerformanceDashboard = () => {
  const { setStoreId } = usePerformanceDashboardState();
  const props = { advancedView: false, setAdvancedView: () => null };
  return (
    <DashboardLayout
      header={<PerformanceDashboardHeader {...props} />}
      onSetStoreId={setStoreId}
    >
      <Box
        sx={{
          justifyContent: "flex-start",
          padding: { xs: 2, lg: 3, xl: 4 },
          paddingLeft: { xl: 12 },
          paddingRight: { xl: 12 },
          paddingBottom: { xs: 0, lg: 0, xl: 0 },
          flexGrow: 1,
          maxHeight: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "row",
          gap: { xs: 2, lg: 3, xl: 4 },
        }}
      >
        <Box display="flex" flexDirection="column" width="100%" gap={1}>
          <Pickers />
          <Info />
        </Box>
        <Riders />
        <Chart />
      </Box>
    </DashboardLayout>
  );
};

export default PerformanceDashboard;
