import React from "react";

import DashboardSpinner from "../../components/DashboardSpinner";
import DashboardHeader from "../../components/dashboard/DashboardHeader";
import OrdersGrid from "../../components/dashboard/OrdersGrid";
import DashboardLayout from "../../layouts/DashboardLayout";
import { useOrdersState } from "./DashboardState";

const Dashboard = () => {
  const ordersState = useOrdersState();
  return (
    <DashboardLayout
      header={
        <DashboardHeader
          advancedView={ordersState.advancedView}
          setAdvancedView={ordersState.setAdvancedView}
        />
      }
      onSetStoreId={ordersState.setStoreId}
    >
      {ordersState.orders && (
        <OrdersGrid
          orders={ordersState.orders}
          purchaseOrders={ordersState.purchaseOrders}
          ordersGraphData={ordersState.ordersGraphData}
          riders={ordersState.riders}
          availableRiders={ordersState.availableRiders}
          isToRideStackingEnabled={ordersState.isToRideStackingEnabled}
          advancedView={ordersState.advancedView}
          setAdvancedView={ordersState.setAdvancedView}
        />
      )}
      {!ordersState.orders && <DashboardSpinner />}
    </DashboardLayout>
  );
};

export default Dashboard;
