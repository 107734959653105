import React from "react";

import { Typography, useMediaQuery } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRowIdGetter,
  GridSortModel,
} from "@mui/x-data-grid";

import { gridFontSize } from "./styles";

const renderColumnHeader = (params: GridColumnHeaderParams) => (
  <Typography
    sx={{ textTransform: "uppercase" }}
    color="#949494"
    fontWeight={500}
    fontSize={{ xs: 12, xl: 22 }}
  >
    {params.colDef.headerName}
  </Typography>
);

const PerformanceDataGrid = (props: {
  rows?: ReadonlyArray<any>;
  columns: GridColDef[];
  justHeader?: boolean;
  hideHeader?: boolean;
  sortModel: GridSortModel;
  setSortModel?: (sortModel: GridSortModel) => void;
  getRowId: GridRowIdGetter<any>;
  headerHeight?: number;
}) => {
  const isXl = useMediaQuery("(min-width:2400px)");

  const rowHeight = isXl ? 75 : 36;

  const headerHeight = isXl ? 50 : 41;

  const height = props.justHeader
    ? headerHeight
    : (props.rows?.length ?? 0) * rowHeight +
      (props.hideHeader ? 0 : headerHeight) +
      2;

  const columnsWithHeader = props.columns.map((column) => ({
    ...column,
    renderHeader: renderColumnHeader,
  }));

  const dataGridStyle = {
    ".MuiDataGrid-columnHeaders": {
      backgroundColor: "#FAFAFA",
    },
    ".MuiDataGrid-cell": {
      borderBottom: "1px solid #EEEEEE",
    },
    height: height,
    fontSize: gridFontSize,
    clipPath: `inset(${props.hideHeader ? 1 : 0}px 0px 1px 0px)`,
    borderLeft: "none",
    borderRight: "none",
  };

  return (
    <DataGrid
      columns={columnsWithHeader}
      rows={props.rows || []}
      getRowId={props.getRowId}
      rowHeight={rowHeight}
      disableColumnMenu
      headerHeight={props.hideHeader ? 0 : headerHeight}
      hideFooter
      sortModel={props.sortModel}
      disableSelectionOnClick
      onSortModelChange={(model) => {
        props.setSortModel ? props.setSortModel(model) : null;
      }}
      components={{
        NoRowsOverlay: () => <></>,
      }}
      sx={dataGridStyle}
    />
  );
};

export default PerformanceDataGrid;
