import React, { useState } from "react";

import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridSortDirection } from "@mui/x-data-grid";

import PurchaseOrderProduct from "../../../data/PurchaseOrderProduct";
import { usePurchaseOrdersState } from "../../../pages/PurchaseOrders/PurchaseOrdersState";
import DashboardSpinner from "../../DashboardSpinner";
import { renderColumnHeader } from "../renderers";

const renderProductCell = (params: { row: PurchaseOrderProduct }) => {
  const { image, title } = params.row;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={3}>
      <Box
        height={48}
        width={48}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {image && (
          <img
            src={image}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
            alt="image"
          />
        )}
      </Box>
      <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{title}</Typography>
    </Box>
  );
};

const renderTextCell = (params: { value?: string }) => {
  const text = params.value;

  return text ? (
    <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{text}</Typography>
  ) : null;
};

const renderReceivedCell = (params: { row: PurchaseOrderProduct }) => {
  const { quantityOrdered, quantityReceived } = params.row;

  const completedPercentage = Math.round(
    (quantityReceived / quantityOrdered) * 100
  );
  const completedColor = completedPercentage < 100 ? "#DE4646" : "#202020";

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
    >
      <Typography sx={{ color: completedColor, fontWeight: 500 }}>
        {completedPercentage}%
      </Typography>
      <Typography sx={{ color: "#202020", fontWeight: 500 }}>
        {quantityReceived}/{quantityOrdered}
      </Typography>
    </Box>
  );
};

const OrderProductsDataGrid = () => {
  const columns: GridColDef[] = [
    {
      headerName: "PRODUCT",
      field: "title",
      flex: 10,
      renderCell: renderProductCell,
      renderHeader: renderColumnHeader,
    },
    {
      headerName: "SKU",
      field: "sku",
      renderCell: renderTextCell,
      renderHeader: renderColumnHeader,
      align: "center",
      flex: 3,
    },
    {
      headerName: "ORDER QUANTITY",
      field: "quantityOrdered",
      renderCell: renderTextCell,
      renderHeader: renderColumnHeader,
      align: "center",
      flex: 3,
    },
    {
      headerName: "RECEIVED",
      field: "received",
      renderCell: renderReceivedCell,
      renderHeader: renderColumnHeader,
      width: 140,
      valueGetter: ({ row }: { row: PurchaseOrderProduct }) =>
        row.quantityReceived / row.quantityOrdered,
    },
    {
      headerName: "IN STOCK",
      field: "inStock",
      renderCell: renderTextCell,
      renderHeader: renderColumnHeader,
      align: "center",
      flex: 3,
    },
  ];

  const { purchaseOrderProducts } = usePurchaseOrdersState();

  const [sortModel, setSortModel] = useState<
    { field: string; sort: GridSortDirection }[]
  >([{ field: "received", sort: "asc" }]);

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      {purchaseOrderProducts ? (
        <DataGrid
          columns={columns}
          rows={purchaseOrderProducts}
          getRowId={(row: PurchaseOrderProduct) => row.sku}
          rowHeight={50}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      ) : (
        <DashboardSpinner />
      )}
    </Box>
  );
};

export default OrderProductsDataGrid;
