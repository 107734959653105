import React from "react";

import DateRangeIcon from "@mui/icons-material/DateRangeRounded";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Box, Typography } from "@mui/material";

function getIcon(icon?: string) {
  switch (icon) {
    case "square":
      return SquareRoundedIcon;
    case "line":
      return HorizontalRuleRoundedIcon;
    case "planned":
      return DateRangeIcon;
    default:
      return SquareRoundedIcon;
  }
}

export function OrdersGraphLabel(props: {
  text: string;
  color?: string;
  icon?: "square" | "line" | "planned";
  textFontSize?: string;
  fontWeight?: string;
  gap?: string;
  iconfontSize?: string;
}) {
  const Icon = getIcon(props.icon);

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={props.gap || "6px"}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
        sx={{
          color: props.color || "rgba(44, 51, 58, 1)",
          fontSize: props.iconfontSize || "26px",
          height: "100%",
        }}
      />
      <Typography
        sx={{
          fontFamily: "Inter",
          fontWeight: props.fontWeight || "600",
          fontSize: props.textFontSize || "12px",
          color: "rgba(37, 45, 74, 1)",
        }}
      >
        {props.text}
      </Typography>
    </Box>
  );
}
