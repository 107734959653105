import { createState, useState } from "@hookstate/core";

import { AdminActions, useAuthState } from "../../authentication/AuthState";
import PurchaseOrder from "../../data/PurchaseOrder";
import PurchaseOrderProduct from "../../data/PurchaseOrderProduct";
import { GetPurchaseOrdersRequestFilter } from "./GetPurchaseOrdersRequest";

const initialStoreId = () => Number(localStorage.getItem("defaultStore")) || 1;

const initialState = {
  purchaseOrders: undefined as Readonly<PurchaseOrder[]> | undefined,
  purchaseOrder: undefined as PurchaseOrder | undefined,
  purchaseOrderProducts: undefined as
    | Readonly<PurchaseOrderProduct[]>
    | undefined,
  loading: true,
  suppliers: undefined as string[] | undefined,
  filter: {
    storeId: initialStoreId() as number | undefined,
    supplierName: "",
    status: undefined,
    date: undefined,
  } as GetPurchaseOrdersRequestFilter,
};

const purchaseOrdersState = createState(initialState);

const resetOrders = (state: any) => {
  state.purchaseOrders.set(undefined);
  state.purchaseOrder.set(undefined);
  state.purchaseOrderProducts.set(undefined);
  state.loading.set(true);
};

export const usePurchaseOrdersState = () => {
  const state = useState(purchaseOrdersState);
  const authState = useAuthState();

  return {
    get purchaseOrders() {
      return state.purchaseOrders.get();
    },
    get purchaseOrder() {
      return state.purchaseOrder.get();
    },
    setPurchaseOrder(value: PurchaseOrder) {
      return state.purchaseOrder.set(value);
    },
    setPurchaseOrders(value: PurchaseOrder[]) {
      return state.purchaseOrders.set(value);
    },
    async fetchPurchaseOrders() {
      const orders = await authState.authenticatedRequest(
        AdminActions.GET_PURCHASE_ORDERS,
        { filter: state.filter.get() }
      );

      const purchaseOrders = orders.data.result as PurchaseOrder[] | undefined;

      state.purchaseOrders.set(purchaseOrders);
      state.loading.set(false);

      if (purchaseOrders) {
        state.purchaseOrder.set(purchaseOrders?.[0]);
      }
      if (!state.suppliers.get() && purchaseOrders) {
        state.suppliers.set(
          [...new Set(purchaseOrders.map((order) => order.supplierName))].sort()
        );
      }
    },
    async fetchPurchaseOrderProducts(orderNumber: string, storeId: number) {
      const response = await authState.authenticatedRequest(
        AdminActions.GET_PURCHASE_ORDER,
        { orderNumber, storeId }
      );

      const products = response.data.result as
        | PurchaseOrderProduct[]
        | undefined;

      state.purchaseOrderProducts.set(products);
      state.loading.set(false);
    },
    get purchaseOrderProducts() {
      return state.purchaseOrderProducts.get();
    },
    resetPurchaseOrderProducts() {
      return state.purchaseOrderProducts.set(undefined);
    },
    get filter() {
      return state.filter.get();
    },
    setStore(storeId: number | undefined) {
      state.filter.set({
        storeId,
        date: undefined,
        supplierName: "",
        status: undefined,
      });
      resetOrders(state);

      state.suppliers.set(undefined);
    },
    setFilter(newValue: Partial<GetPurchaseOrdersRequestFilter>) {
      state.filter.merge(newValue);

      resetOrders(state);
    },
    resetFilter() {
      state.filter.set((filter) => ({
        storeId: filter.storeId,
        date: undefined,
        supplierName: "",
        status: undefined,
      }));

      resetOrders(state);
    },
    get loading() {
      return state.loading.get();
    },
    get suppliers() {
      return state.suppliers.get();
    },
  };
};
