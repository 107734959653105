import React from "react";

import { CssBaseline } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Router from "./Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  return (
    <React.Fragment>
      <ToastContainer
        autoClose={1500}
        closeOnClick={true}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router />
      </LocalizationProvider>
    </React.Fragment>
  );
}
