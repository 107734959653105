import moment from "moment";
import { Audio } from "ts-audio";

import { createState, useState } from "@hookstate/core";

import {
  AdminActions,
  UseAuthState,
  useAuthState,
} from "../../authentication/AuthState";
import { defaultOrdersGraphData } from "../../components/dashboard/OrdersGraph/OrdersGraphChartOptions";
import Order, {
  APIOrder,
  getDeliveryTimeInMinutes,
  getMinutesLeft,
  getMinutesWaiting,
  getOrderAssignee,
  getPickingCompletedTimestampPerStack,
  getStackEstimatedDeliveryWithoutDelays,
  hasLockedRestackingPartner,
  isBagPickingCompleted,
  isStackedAndSamePartnerStatus,
  isStackedOrder,
  wasPickedLessThanTenSecAgo,
} from "../../data/Order";
import OrdersGraphData from "../../data/OrdersGraphData";
import PurchaseOrder from "../../data/PurchaseOrder";
import Stocktake from "../../data/Stocktake";

import { AvailableRider, DashboardRider } from "../../data/Riders";
import { concatRidersName } from "../../components/dashboard/helpers/ridersFormatting";
import { getTrafficLightStatus } from "../../components/dashboard/helpers/getTrafficLightStatus";

const initialState = {
  isLoadingOrders: false,
  orders: undefined as Order[] | undefined,
  isToRideStackingEnabled: false,
  currentDeliveryEstimate: 0,
  purchaseOrders: undefined as PurchaseOrder[] | undefined,
  storeId: undefined as number | undefined,
  stocktakesCount: undefined as number | undefined,
  soundOn: true,
  ordersGraphData: defaultOrdersGraphData as OrdersGraphData,
  advancedView: false,
  riders: undefined as DashboardRider[] | undefined,
  availableRiders: undefined as AvailableRider[] | undefined,
};

type DashboardState = typeof initialState;

const dashboardState = createState<DashboardState>(initialState);

let startedInterval = false;

export const compareOrders = (order1: Order, order2: Order) => {
  return (
    moment(order1.sortTimestamp).diff(order2.sortTimestamp) ||
    moment(order1.estimatedDeliveryWithoutDelays).diff(
      order2.estimatedDeliveryWithoutDelays
    )
  );
};

const comparePurchaseOrders = (
  order1: PurchaseOrder,
  order2: PurchaseOrder
) => {
  return (
    moment.duration(moment(order2.date).diff(order1.date)).asDays() ||
    order2.itemsTotal - order1.itemsTotal
  );
};

export const loadOrders = async (authState: UseAuthState, storeId: number) => {
  const response = await authState.anonymousRequest(
    AdminActions.GET_DASHBOARD,
    {
      item: "orders",
      storeId,
    }
  );

  const result = response.data.result;
  const ordersRaw = result.dashboardOrders as APIOrder[];

  const estimatedDeliveryWithoutDelaysPerStack =
    getStackEstimatedDeliveryWithoutDelays(ordersRaw);

  const pickingCompletedTimestampPerStack =
    getPickingCompletedTimestampPerStack(ordersRaw);

  const orders = ordersRaw
    .map((orderRaw: APIOrder) => {
      const isLatched = isStackedAndSamePartnerStatus(orderRaw, ordersRaw);
      const isStacked = isStackedOrder(orderRaw, ordersRaw);

      return {
        ...orderRaw,
        isStacked,
        isLatched,
        stackEstimatedDeliveryWithoutDelays: orderRaw.orderStackId
          ? estimatedDeliveryWithoutDelaysPerStack.get(orderRaw.orderStackId)
          : orderRaw.estimatedDeliveryWithoutDelays,
        stackPickingCompletedTimestamp: orderRaw.orderStackId
          ? pickingCompletedTimestampPerStack.get(orderRaw.orderStackId)
          : null,
      } as Order;
    })
    .map((order) => {
      const minutesLeft = getMinutesLeft(order);
      const minutesWaiting = getMinutesWaiting(order);
      const assignee = getOrderAssignee(order);

      const delayed = minutesLeft < 0;

      const suggestedRider =
        typeof order.suggestedRider === "string"
          ? { name: order.suggestedRider, status: "busy" }
          : order.suggestedRider;

      return {
        ...order,
        suggestedRider: suggestedRider ?? null,
        bagPickingCompleted: isBagPickingCompleted(order),
        delayed,
        minutesLeft,
        hasLockedRestackingPartner: hasLockedRestackingPartner(
          order,
          ordersRaw
        ),
        minutesWaiting,
        wasPickedLessThanTenSecAgo: wasPickedLessThanTenSecAgo(order),
        assignee,
        deliveryTimeInMinutes: getDeliveryTimeInMinutes(order),
      };
    })
    .sort(compareOrders);

  return {
    currentDeliveryEstimate: result.currentDeliveryEstimate,
    isToRideStackingEnabled: result.isToRideStackingEnabled,
    orders,
    ordersGraphData: result.ordersGraphData,
    riders: result.riders as AvailableRider[],
  };
};

export const loadPurchaseOrders = async (
  authState: UseAuthState,
  storeId: number
) => {
  const response = await authState.anonymousRequest(
    AdminActions.GET_DASHBOARD,
    {
      item: "purchaseOrders",
      storeId,
    }
  );

  const purchaseOrdersRaw = response.data.result as PurchaseOrder[];

  if (!purchaseOrdersRaw) {
    return [];
  }

  const purchaseOrders = purchaseOrdersRaw.map((order) => {
    return {
      ...order,
      rateCompleted: order.itemsCompleted / order.itemsTotal,
    } as PurchaseOrder;
  });

  const todayPurchaseOrders = purchaseOrders.filter(
    (order) =>
      moment(order.date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
  );
  const notTodayPurchaseOrders = purchaseOrders.filter(
    (order) =>
      moment(order.date).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") &&
      order.rateCompleted !== undefined &&
      order.rateCompleted < 0.75
  );

  return [...todayPurchaseOrders, ...notTodayPurchaseOrders].sort(
    comparePurchaseOrders
  );
};

const loadStocktakesCount = async (
  authState: UseAuthState,
  storeId: number
) => {
  const response = await authState.anonymousRequest(
    AdminActions.GET_DASHBOARD,
    {
      item: "stocktakes",
      storeId,
    }
  );

  const stocktakes = response.data.result as Stocktake[];

  return new Set(
    stocktakes.map((stocktake) =>
      "sku" in stocktake ? stocktake.sku : stocktake.dataValues.sku
    )
  ).size;
};

const playOrderSound = () => {
  // const file = "./newOrder.vaw";

  // Uncomment to celebrate April Fools
  let file = "./newOrder.wav";
  const isAprilFools = moment().format("MM-DD") === "04-01";
  if (isAprilFools && Math.random() > 0.9) {
    file = "./ahShitHereWeDoAgain.mp3";
  }

  // eslint-disable-next-line new-cap
  const audio = Audio({ file: file, volume: 5 });

  try {
    audio.play();
  } catch (e) {
    console.error(e);
  }
};

export function useOrdersState() {
  const state = useState(dashboardState);
  const authState = useAuthState();

  const ordersInterval = async () => {
    const storeId = state.storeId.get();
    if (!storeId) {
      return;
    }

    const isLoadingOrders = state.isLoadingOrders.get();
    if (isLoadingOrders) {
      // Orders are already loading
      console.log("Skip orders loading, already loading");
      return;
    }

    try {
      state.isLoadingOrders.set(true);
      const {
        currentDeliveryEstimate,
        isToRideStackingEnabled,
        orders: dashboardOrders,
        ordersGraphData,
        riders,
      } = await loadOrders(authState, storeId);

      const areNewOrders = dashboardOrders.some(
        (order) =>
          !(state.orders.get() || []).some(
            (oldOrder) => oldOrder.displayNumber === order.displayNumber
          )
      );

      const availableRiders = riders
        .filter((rider) => rider.status === "available")
        .map((rider) => ({
          ...rider,
          trafficLightStatus:
            getTrafficLightStatus(
              riders,
              concatRidersName(rider.firstName, rider.lastName),
              dashboardOrders
            ) ?? null,
        }))
        .sort(
          (a, b) =>
            (moment(a.availableSinceTimestamp).valueOf() || 0) -
            (moment(b.availableSinceTimestamp).valueOf() || 0)
        );

      const ordersFirstLoad = state.orders.get() === undefined;

      if (!ordersFirstLoad && areNewOrders && state.soundOn.get()) {
        playOrderSound();
      }

      state.merge({
        ordersGraphData: ordersGraphData || defaultOrdersGraphData,
        riders,
        currentDeliveryEstimate,
        isToRideStackingEnabled,
        orders: dashboardOrders,
        availableRiders,
      });
    } finally {
      state.isLoadingOrders.set(false);
    }
  };
  const purchaseOrdersInterval = async () => {
    const storeId = state.storeId.get();
    if (!storeId) {
      return;
    }

    const purchaseOrders = await loadPurchaseOrders(authState, storeId);

    state.merge({
      purchaseOrders,
    });
  };

  const stocktakesInterval = async () => {
    const storeId = state.storeId.get();
    if (!storeId) {
      return;
    }

    const stocktakesCount = await loadStocktakesCount(authState, storeId);

    state.merge({
      stocktakesCount,
    });
  };

  if (!startedInterval) {
    startedInterval = true;
    void ordersInterval();
    void purchaseOrdersInterval();
    void stocktakesInterval();
    setInterval(ordersInterval, 3000);
    setInterval(purchaseOrdersInterval, 60000);
    setInterval(stocktakesInterval, 60000);
  }

  return {
    get isLoadingOrders() {
      return state.isLoadingOrders.get();
    },
    get orders() {
      return state.orders.get();
    },
    get isToRideStackingEnabled() {
      return state.isToRideStackingEnabled.get();
    },
    get currentDeliveryEstimate() {
      return state.currentDeliveryEstimate.get();
    },
    get purchaseOrders() {
      return state.purchaseOrders.get();
    },
    get stocktakesCount() {
      return state.stocktakesCount.get();
    },
    get ordersGraphData() {
      return state.ordersGraphData.get();
    },
    get riders() {
      return state.riders.get();
    },
    get availableRiders() {
      return state.availableRiders.get();
    },
    setStoreId(storeId: number) {
      state.ordersGraphData.set(defaultOrdersGraphData);
      state.storeId.set(storeId);
      state.orders.set(undefined);
      state.riders.set(undefined);
      state.purchaseOrders.set(undefined);
      state.stocktakesCount.set(undefined);

      ordersInterval();
      purchaseOrdersInterval();
      stocktakesInterval();
    },
    get soundOn() {
      return state.soundOn.get();
    },
    setSoundOn(muted: boolean) {
      state.soundOn.set(muted);
    },
    get advancedView() {
      return state.advancedView.get();
    },
    setAdvancedView(advancedView: boolean) {
      state.advancedView.set(advancedView);
    },
  };
}
