import config from "./config";

const buildInfo: Record<string, unknown> = {
  gitRev: process.env["GITHUB_SHA"] || "local",
  gitHubRefName: process.env["GITHUB_REF_NAME"] || "local",
  isDev: config.isDev,
};

/**
 * Console logs the essential build info and adds a more detailed object to
 * window.buildInfo. The latter means that you can always write buildInfo in the\
 * development console to get all info.
 */
export function makeBuildInfoAvailable() {
  console.log(JSON.stringify(buildInfo, null, 4));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.buildInfo = { ...buildInfo, ...config };
}
