import React, { useCallback } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { usePurchaseOrdersState } from "../../../pages/PurchaseOrders/PurchaseOrdersState";

const StatusFilter = () => {
  const { filter, setFilter } = usePurchaseOrdersState();

  const value = filter.status || "";
  const onChange = useCallback(
    (event: SelectChangeEvent) =>
      setFilter({ status: event.target.value || undefined }),
    [setFilter]
  );

  return (
    <FormControl sx={{ width: 160 }}>
      <InputLabel id="po-status-label">Status</InputLabel>
      <Select labelId="po-status-label" value={value} onChange={onChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="completed">Completed</MenuItem>
        <MenuItem value="uncompleted">Uncompleted</MenuItem>
      </Select>
    </FormControl>
  );
};

export default StatusFilter;
