import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./authentication/Login";
import RequireAuth from "./authentication/RequireAuth";
import config from "./config";
import { Panel } from "./layouts/Panel";
import Dashboard from "./pages/Dashboard/Dashboard";
import NoMatch from "./pages/NoMatch";
import pages from "./pages/Pages";
import PerformanceDashboard from "./pages/PerformanceDashboard/PerformanceDashboard";
import StaffingRedirect from "./pages/StaffingRedirect/StaffingRedirect";
import StaffingForecast from "./pages/StaffingForecast/StaffingForecast";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/performance" element={<PerformanceDashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/staffing-forecast" element={<StaffingForecast />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Panel />
          </RequireAuth>
        }
      >
        {pages.map(
          (page) =>
            (config.isDev || page.enableForProduction) && (
              <Route
                key={page.path}
                path={page.path}
                element={<page.element />}
              />
            )
        )}
        <Route path="/staffing-redirect" element={<StaffingRedirect />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
