import React from "react";

import { Typography } from "@mui/material";

export const renderColumnHeader = (params: {
  colDef: { headerName?: string };
}) => {
  return (
    <Typography sx={{ color: "#737272", fontSize: 16, fontWeight: 600 }}>
      {params.colDef.headerName}
    </Typography>
  );
};
