import React from "react";

import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";

import OrdersGraphData from "../../../data/OrdersGraphData";
import { options } from "./OrdersGraphChartOptions";

ChartJS.register(...registerables);

const labels = [
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];

const forecastLineChart = {
  label: "Forecast line",
  type: "line" as const,
  lineTension: 0.35,
  data: [] as number[],
  borderColor: "rgba(87, 102, 117, 1)",
  borderWidth: 1,
  order: 0,
};
const upcomingPlannedBarChart = {
  label: "Upcoming planned bar",
  type: "bar" as const,
  data: [] as number[],
  backgroundColor: "rgba(167, 179, 190, 1)",
  order: 1,
  borderRadius: 4,
};
const plannedBarChart = {
  label: "Planned bar",
  type: "bar" as const,
  data: [] as number[],
  backgroundColor: "rgba(21, 118, 61, 1)",
  order: 2,
  borderRadius: 4,
};
const unplannedBarChart = {
  label: "Unplanned bar",
  type: "bar" as const,
  data: [] as number[],
  backgroundColor: "rgba(172, 218, 191, 1)",
  order: 3,
  borderRadius: 4,
};

export function OrdersChart(props: { rawData: OrdersGraphData }) {
  unplannedBarChart.data = [...props.rawData.unplanned];
  plannedBarChart.data = [...props.rawData.planned];
  upcomingPlannedBarChart.data = [...props.rawData.upcomingPlanned];
  forecastLineChart.data = [...props.rawData.forecast];

  const data = {
    labels,
    datasets: [
      forecastLineChart,
      upcomingPlannedBarChart,
      plannedBarChart,
      unplannedBarChart,
    ],
  };

  return <Chart type="bar" options={options} data={data} />;
}
