import React from "react";

import moment from "moment";

import { Typography } from "@mui/material";

const OrderDeliveryDate = (props: { date: string }) => {
  const dateFormatted = moment(props.date).format("DD/MM");

  return (
    <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
      {dateFormatted}
    </Typography>
  );
};

export default OrderDeliveryDate;
