import React, { useCallback } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { usePurchaseOrdersState } from "../../../pages/PurchaseOrders/PurchaseOrdersState";

const SupplierFilter = () => {
  const { suppliers, filter, setFilter } = usePurchaseOrdersState();

  const value = filter.supplierName;

  const onChange = useCallback(
    (event: SelectChangeEvent) =>
      setFilter({ supplierName: event.target.value }),
    [setFilter]
  );

  return (
    <FormControl sx={{ width: 160 }}>
      <InputLabel id="po-supplier-label">Supplier</InputLabel>
      <Select labelId="po-supplier-label" value={value} onChange={onChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {suppliers?.map((supplier) => (
          <MenuItem value={supplier} key={supplier}>
            {supplier}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SupplierFilter;
