import React from "react";

import { PriorityHigh } from "@mui/icons-material";
import { Typography } from "@mui/material";

import DashboardCard from "./DashboardCard";
import RiderGridHeader from "./RiderGridHeader";

const Paragraph = (props: { children: React.ReactNode }) => (
  <Typography fontSize={{ xs: 14, xl: 24 }}>{props.children}</Typography>
);

const Header = (props: { children: React.ReactNode }) => (
  <Typography fontSize={{ xs: 14, xl: 24 }} fontWeight={500} color="#4D4D4D">
    {props.children}
  </Typography>
);

const Info = () => {
  return (
    <DashboardCard title={"Info:"} sx={{ minHeight: "20%", flex: "1 0 20%" }}>
      <Typography sx={{ overflow: "scroll" }}>
        <Header>STATUS</Header>
        <Paragraph>
          Status (x min) = To customer / At customer / Returning / Stand by and
          the time at current statusAvg round trip = Time from store and back to
          store divided by number of orders in the trip.
          <ul>
            <li>Ex: Stacked 2-order trip</li>
            <li>Start time: 1500</li>
            <li>Return to store after 2 drops: 1520</li>
            <li>Avg round trop time: 20/2 = 10 min</li>
          </ul>
        </Paragraph>
        <Header>ORDERS</Header>
        <Paragraph>Orders = Number of orders deliveries</Paragraph>
        <Paragraph>
          Punched in, not operational = Signed in to Planday but not available
          to ride in Kavall rider app. Riders are expected to be operational
          once they start their shifts. Bikes and gear ready and available in
          Rider App
        </Paragraph>
        <br />
        <RiderGridHeader
          icon={<PriorityHigh sx={{ color: "#FFF", fontSize: 18 }} />}
          color="#FFCB14"
        >
          Punched in, not operational
        </RiderGridHeader>
        <Paragraph>
          Punched in, not operational = Signed in to Planday but not available
          to ride in Kavall rider app. Riders are expected to be operational
          once they start their shifts. Bikes and gear ready and available in
          Rider App
        </Paragraph>
      </Typography>
    </DashboardCard>
  );
};

export default Info;
