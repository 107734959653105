import { APIOrder } from "../data/Order";

const colors = [
  "#FFE600",
  "#FAB3CC",
  "#62AA06",
  "#FF9900",
  "#55D8E0",
  "#EC4A4A",
  "#0063D8",
  "#8ADC21",
  "#C198DB",
  "#900E0E",
  "#D900DD",
  "#686868",
  "#19716B",
  "#4FFFAA",
];

const useStackColor = (order: APIOrder): string | undefined => {
  if (order.color === null) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return colors[order.color] || "#BBB";
};

export default useStackColor;
