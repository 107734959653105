import React, { useEffect } from "react";

import { Box, Card } from "@mui/material";

import { usePurchaseOrdersState } from "../../../pages/PurchaseOrders/PurchaseOrdersState";
import NoMatch from "../NoMatch";
import OrderHeader from "../OrderHeader";
import OrderProductsDataGrid from "../grids/OrderProductsDataGrid";

const OrderCard = () => {
  const { purchaseOrder, fetchPurchaseOrderProducts, loading } =
    usePurchaseOrdersState();

  useEffect(() => {
    if (purchaseOrder) {
      fetchPurchaseOrderProducts(purchaseOrder.number, purchaseOrder.storeId);
    }
  }, [purchaseOrder?.number]);

  return purchaseOrder ? (
    <Card
      sx={{ padding: 4, flexGrow: 1, display: "flex", flexDirection: "column" }}
    >
      <OrderHeader />
      <OrderProductsDataGrid />
    </Card>
  ) : (
    // Centering text on the screen, made without Stack Overflow 😎
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {loading ? "Loading orders..." : <NoMatch />}
    </Box>
  );
};

export default OrderCard;
