import { AvailableRider } from "../../../data/Riders";
import { concatRidersName } from "./ridersFormatting";

function getAvailableRider(
  availableRiders: AvailableRider[] | undefined,
  suggestedRider: string | null
) {
  if (!availableRiders || !suggestedRider) {
    return;
  }

  const availableRider = availableRiders?.find(
    (availableRider) =>
      concatRidersName(availableRider.firstName, availableRider.lastName) ===
      suggestedRider.toUpperCase()
  );

  return availableRider;
}

export default getAvailableRider;
