export enum RiderStatus {
  Idle = "Idle",
  Standby = "Standby",
  Returning = "Returning",
  At_Customer = "At customer",
  To_Customer = "To customer",
  Late = "Late",
}

export interface RiderRaw {
  rider_id: string;
  status: RiderStatus;
  first_name: string | null;
  last_name: string | null;
  punched_in: boolean;
  operational: boolean;
  status_duration_minutes: number;
  orders: number;
  worked_time_minutes: number;
  avg_round_trip_minutes: number | null;
  avg_round_trip_km: number | null;
}

export interface Rider extends RiderRaw {
  name: string;
}

export interface PickerRaw {
  employeeId: number;
  firstName: string;
  lastName: string;
  utr: number | null;
  pf: number | null;
  pfr: number | null;
  avgPickingTime: number | null;
  avgPickingTimePerItem: number | null;
  orders: number | null;
}

export interface Picker extends PickerRaw {
  name: string;
}

export interface RidersShiftsSummary {
  morning: { actualMinutes: number; targetMinutes: number };
  evening: { actualMinutes: number; targetMinutes: number };
}

interface PickersShiftSummary {
  utr: number | null;
  pfr: number | null;
  avgPickingTime: number | null;
  avgPickingTimePerItem: number | null;
  orders: number | null;
}

export interface PickersShiftsSummary {
  morning: PickersShiftSummary;
  evening: PickersShiftSummary;
}

interface PerformanceOld {
  shiftsSummary: RidersShiftsSummary;
  performanceData: RiderRaw[];
}

interface RidersPerformance {
  shiftsSummary: RidersShiftsSummary;
  performance: RiderRaw[];
}

interface PickersPerformance {
  performance: PickerRaw[];
  shiftsSummary: PickersShiftsSummary;
}

interface OrderPerformance {
  id: number;
  performance: "inTime" | "almostInTime" | "notInTime";
}

export interface OrdersPerformance {
  performance: OrderPerformance[];
}

interface PerformanceNew {
  riders: RidersPerformance;
  pickers: PickersPerformance;
  orders: OrdersPerformance;
}

type Performance = PerformanceOld | PerformanceNew;

export default Performance;
