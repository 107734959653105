const IS_DEV = process.env["ADMIN_ENV"] !== "prod";

export default {
  isDev: IS_DEV,
  apiUrl: IS_DEV
    ? "https://europe-west3-getgaston-test.cloudfunctions.net/adminApi"
    : "https://europe-west3-gastrofy-app.cloudfunctions.net/adminApi",
  oAuthClientId: IS_DEV
    ? "1013480092116-jeokbe1uddq14d5hp99eogmoedv25859.apps.googleusercontent.com"
    : "216227539372-c5rkp0qf0p5jhgvu5rdc1djbahb0ifor.apps.googleusercontent.com",
};
