import { Button, Grid } from "@mui/material";
import React from "react";

type IButtonProps = {
  content: string;
  theme: { backgroundColor: string; color: string };
  isGenerating: boolean;
  onHandleAbort: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const AbortButton = (props: IButtonProps) => {
  const { content, theme, isGenerating, onHandleAbort } = props;
  return (
    <Grid display="flex" gap={2}>
      <Button
        onClick={onHandleAbort}
        variant="contained"
        disableElevation
        sx={{
          display: isGenerating ? "block" : "none",
          backgroundColor: theme.backgroundColor,
          color: theme.color,
          maxHeight: "100px",
          maxWidth: "150px",
          "&:hover": { backgroundColor: theme.backgroundColor },
        }}
      >
        {content}
      </Button>
    </Grid>
  );
};

export default AbortButton;
