import React, { ReactNode, useEffect } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Box, ThemeProvider, createTheme } from "@mui/material";

import GoFullScreenButton from "../components/dashboard/GoFullScreenButton";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    lg2: true; // adds the `lg2` breakpoint
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "Roboto",
  },
  palette: {
    background: { default: "#F9F9F9", paper: "#FFFFFF" },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      // Custom breakpoint to change margin.
      lg2: 1500,
      // Custom breakpoint for 4K screen
      xl: 2400,
    },
  },
});

export default function DashboardLayout(props: {
  header: ReactNode;
  onSetStoreId: (storeId: number) => void;
  children: ReactNode | ReactNode[];
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const storeId = Number(searchParams.get("store"));
    if (!storeId) {
      const defaultStore = localStorage.getItem("defaultStore") || 1;
      navigate(`${location.pathname}?store=${defaultStore}`);
    }
    props.onSetStoreId(storeId);
  }, [searchParams, navigate]);

  return (
    <>
      <GoFullScreenButton />
      <div id="dashboard">
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: { md: "100vh" },
              maxHeight: { md: "100vh" },
              backgroundColor: theme.palette.background.paper,
            }}
          >
            {props.header}
            {props.children}
          </Box>
        </ThemeProvider>
      </div>
    </>
  );
}
