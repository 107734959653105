import React from "react";

import { Box, Typography } from "@mui/material";

import { black, boldFontWeight, fontWeight } from "../styles";

const NumberBox = (props: {
  number: number | string;
  bold?: boolean | undefined;
  boxColor?: string | undefined;
  textColor?: string | undefined;
}) => (
  <Box
    sx={{
      height: { xs: 26, md: 18, lg: 26, xl: 48 },
      backgroundColor: props.boxColor || "#F3F4F7",
      borderRadius: { xs: 28, md: 24, lg: 28, xl: 48 },
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      paddingRight: {
        xs: "9px",
        md: "6px",
        lg: "9px",
        xl: "16px",
      },
      paddingLeft: {
        xs: "9px",
        md: "6px",
        lg: "9px",
        xl: "16px",
      },
    }}
  >
    <Typography
      sx={{
        color: black,
        fontSize: { xs: 12, md: 10, lg2: 14, xl: 24 },
        fontWeight: props.bold ? boldFontWeight : fontWeight,
      }}
    >
      {props.number || 0}
    </Typography>
  </Box>
);

export default NumberBox;
