import React from "react";

import { Box } from "@mui/material";

import DateFilter from "./DateFilter";
import StatusFilter from "./StatusFilter";
import SupplierFilter from "./SupplierFilter";

const ListFilters = () => (
  <Box display="flex" flexDirection="row" gap={3}>
    <SupplierFilter />
    <StatusFilter />
    <DateFilter />
  </Box>
);

export default ListFilters;
