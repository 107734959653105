import React, { useCallback } from "react";

import CheckIcon from "@mui/icons-material/Check";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";

import PurchaseOrder from "../../../data/PurchaseOrder";
import { usePurchaseOrdersState } from "../../../pages/PurchaseOrders/PurchaseOrdersState";
import DashboardSpinner from "../../DashboardSpinner";
import OrderDeliveryDate from "../OrderDeliveryDate";
import OrderNumber from "../OrderNumber";
import OrderSupplier from "../OrderSupplier";
import PurchaseOrderFulfillment from "../PurchaseOrderFulfillment";
import { renderColumnHeader } from "../renderers";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { darkGrey } from "../../dashboard/styles";
import { newPurchaseOrderStatus } from "../../../pages/PurchaseOrders/ChangePurchaseOrderStatusRequest";
import IconButton from "@mui/material/IconButton";
import { AdminActions, useAuthState } from "../../../authentication/AuthState";

const renderPurchaseOrderStatusIcon = (params: { row: PurchaseOrder }) => {
  const { purchaseOrders, setPurchaseOrders } = usePurchaseOrdersState();
  const authState = useAuthState();

  const changePurchaseOrderStatus = async (
    orderNumber: string,
    newStatus: newPurchaseOrderStatus
  ) => {
    const response = await authState.authenticatedRequest(
      AdminActions.CHANGE_PURCHASE_ORDER_STATUS,
      { orderNumber, newStatus }
    );
    if (response.status === 200) {
      if (purchaseOrders) {
        const modifiedPOs = purchaseOrders.map((order) => {
          if (order.number === orderNumber) {
            const status = order.status === "Open" ? "BitlogClosed" : "Open";
            return {
              ...order,
              status,
            };
          }
          return order;
        });
        setPurchaseOrders(JSON.parse(JSON.stringify(modifiedPOs)));
      }
    }
  };

  const purchaseOrder = params.row;
  if (
    purchaseOrder.itemsCompleted === purchaseOrder.itemsTotal ||
    purchaseOrder.status === "Completed"
  ) {
    return (
      <IconButton aria-label="PO completed" disabled={true}>
        <CheckIcon sx={{ color: "#6ACF09", fontSize: 24 }} />
      </IconButton>
    );
  }

  let newStatus = newPurchaseOrderStatus.Open;
  let iconButton = <LockIcon sx={{ color: darkGrey, fontSize: 24 }} />;
  if (purchaseOrder.status === "Open") {
    newStatus = newPurchaseOrderStatus.Unknown;
    iconButton = <LockOpenIcon sx={{ color: darkGrey, fontSize: 24 }} />;
  }

  return (
    <IconButton
      onClick={() => {
        changePurchaseOrderStatus(purchaseOrder.number, newStatus);
      }}
      aria-label="toggle PO status"
    >
      {iconButton}
    </IconButton>
  );
};

const renderNumber = (params: { value?: string }) => {
  return params.value ? <OrderNumber number={params.value} /> : null;
};

const renderSupplier = (params: { value?: string }) => {
  const supplier = params.value;

  return supplier ? <OrderSupplier supplier={supplier} /> : null;
};

const renderDelivery = (params: { value?: string }) => {
  const date = params.value;

  return date ? <OrderDeliveryDate date={date} /> : null;
};

const renderStatus = (params: { row: PurchaseOrder }) => {
  return <PurchaseOrderFulfillment {...params.row} variant="admin" />;
};

const OrdersDataGrid = () => {
  const columns: GridColDef[] = [
    {
      field: "completed",
      headerName: "COMPLETED",
      renderHeader: () => <></>,
      renderCell: renderPurchaseOrderStatusIcon,
      width: 20,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: "number",
      headerName: "NUMBER",
      renderHeader: renderColumnHeader,
      renderCell: renderNumber,
    },
    {
      field: "supplierName",
      headerName: "SUPPLIER",
      flex: 1,
      renderHeader: renderColumnHeader,
      renderCell: renderSupplier,
    },
    {
      field: "date",
      headerName: "DELIVERY",
      renderHeader: renderColumnHeader,
      renderCell: renderDelivery,
    },
    {
      field: "status",
      headerName: "STATUS",
      valueGetter: (params) =>
        `${params.row.itemsCompleted}/${params.row.itemsTotal}`,
      renderHeader: renderColumnHeader,
      renderCell: renderStatus,
      width: 110,
    },
  ];

  const {
    purchaseOrders,
    purchaseOrder: selectedOrder,
    setPurchaseOrder: setSelectedOrder,
    resetPurchaseOrderProducts,
  } = usePurchaseOrdersState();

  const onSelectionModelChange = useCallback(
    (numbers: GridSelectionModel) => {
      const number = numbers.at(-1);

      if (purchaseOrders) {
        const selectedOrder = purchaseOrders.find(
          (order) => order.number === number
        );

        if (selectedOrder) {
          resetPurchaseOrderProducts();
          setSelectedOrder(JSON.parse(JSON.stringify(selectedOrder)));
        }
      }
    },
    [setSelectedOrder, purchaseOrders]
  );

  return purchaseOrders !== undefined ? (
    <DataGrid
      columns={columns}
      rows={purchaseOrders}
      getRowId={(row: PurchaseOrder) => row.number}
      selectionModel={selectedOrder?.number || ""}
      onSelectionModelChange={onSelectionModelChange}
    />
  ) : (
    <DashboardSpinner />
  );
};

export default OrdersDataGrid;
