import React from "react";

import { Box, Typography } from "@mui/material";

import { boldFontWeight } from "../dashboard/styles";
import ShiftLabel from "./ShiftLabel";

export enum Shift {
  morning = "morning",
  evening = "evening",
}

const ShiftSummary = (props: {
  shift: Shift;
  minutes: number;
  minutesTarget: number;
}) => {
  return (
    <Box
      flexGrow={1}
      justifyContent="flexEnd"
      gap={1}
      flexDirection="row"
      display="flex"
      alignItems="center"
    >
      <Box
        width={{ xs: 240, xl: 330 }}
        height={{ xs: 24, xl: 32 }}
        sx={{ backgroundColor: "#FAFAFA", borderRadius: 4 }}
        display="flex"
        alignItems="center"
      >
        <ShiftLabel shift={props.shift} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <Typography fontSize={{ xs: 12, xl: 20 }} fontWeight={boldFontWeight}>
            {Math.round(props.minutes)} min
          </Typography>
          <Typography sx={{ marginLeft: 1 }} fontSize={{ xs: 12, xl: 20 }}>
            ({Math.round(props.minutesTarget)} min target)
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ShiftSummary;
