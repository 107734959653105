import React, { useCallback } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { usePurchaseOrdersState } from "../../pages/PurchaseOrders/PurchaseOrdersState";
import { useStoreState } from "../../data/StoreState";

const StoreSelect = () => {
  const { filter, setStore } = usePurchaseOrdersState();

  const setStoreId = useCallback(
    (event: SelectChangeEvent) => {
      const storeId = Number(event.target.value) || undefined;
      setStore(storeId);

      if (storeId) {
        localStorage.setItem("defaultStore", String(storeId));
      }
    },
    [setStore]
  );

  const { stores } = useStoreState();

  return (
    <FormControl variant="standard" sx={{ width: 160, marginTop: -1 }}>
      <InputLabel id="po-store-label">Store</InputLabel>
      <Select
        value={String(filter.storeId)}
        onChange={setStoreId}
        labelId="po-store-label"
      >
        <MenuItem value={""} key={""}>
          <em>None</em>
        </MenuItem>
        {stores.map((store) => (
          <MenuItem value={store.id} key={store.id}>
            {store.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StoreSelect;
