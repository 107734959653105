import React from "react";
import { Box } from "@mui/material";

import Hourglass from "../Hourglass";
import { borderRadius, markerSize } from "../styles";

const StackMarker = (props: {
  advancedView: boolean;
  isToRideStackingEnabled: boolean;
  stackColor?: string | undefined;
  hasLockedRestackingPartner?: boolean | undefined;
  withHourglass?: boolean | undefined;
  isManuallyHandled?: boolean | undefined;
}) => {
  const sx = {
    width: markerSize,
    height: "100%",
    borderRadius: borderRadius,
    background: "",
    backgroundColor: props.stackColor || "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  };

  const { advancedView, isToRideStackingEnabled } = props;

  const hiddenStack = Boolean(
    !advancedView &&
      props.stackColor &&
      isToRideStackingEnabled &&
      !props.hasLockedRestackingPartner &&
      !props.isManuallyHandled
  );

  if (hiddenStack) {
    sx.background = `repeating-linear-gradient(-45deg, #B9C2CB, #B9C2CB 2px, #EBEDF0 2px, #EBEDF0 10px)`;
  }

  return (
    <Box component="div" sx={sx}>
      {props.withHourglass ? <Hourglass white={!hiddenStack} /> : null}
    </Box>
  );
};

export default StackMarker;
