import React from "react";

import DashboardCard from "./DashboardCard";
import RidersGrid from "./RidersGrid";
import ShiftsSummary from "./ShiftsSummary";

const Riders = () => (
  <DashboardCard title="Riders" header={<ShiftsSummary />}>
    <RidersGrid />
  </DashboardCard>
);
export default Riders;
