import React from "react";

import { Box } from "@mui/material";

import {
  black,
  boldFontWeight,
  borderRadius,
  green,
  orange,
  poFontSize,
  red,
  yellow,
} from "../dashboard/styles";

const PurchaseOrderFulfillment = (props: {
  itemsCompleted: number;
  itemsTotal: number;
  variant?: string;
}) => {
  const completed = props.itemsCompleted / props.itemsTotal;

  let boxColor: string;
  switch (true) {
    case completed > 0.75:
      boxColor = green;
      break;
    case completed > 0.5:
      boxColor = yellow;
      break;
    case completed > 0.25:
      boxColor = orange;
      break;
    default:
      boxColor = red;
  }

  // TODO proper MUI theme for all admin will fix this
  const width =
    props.variant === "admin"
      ? 96
      : { xs: "80px", md: "55px", lg: "80px", xl: "202px" };
  const fontSize = props.variant === "admin" ? poFontSize.lg : poFontSize;
  const height = props.variant === "admin" ? 40 : "100%";

  return (
    <Box
      sx={{
        backgroundColor: boxColor,
        width,
        height,
        color: black,
        borderRadius,
        display: "flex",
        justifyContent: props.variant === "admin" ? "center" : "right",
        paddingRight: props.variant === "admin" ? 0 : { xs: 1, xl: "24px" },
        alignItems: "center",
        fontSize,
        fontWeight: boldFontWeight,
      }}
    >
      {props.itemsCompleted}/{props.itemsTotal}
    </Box>
  );
};

export default PurchaseOrderFulfillment;
