import React from "react";

import { Box, Drawer, Toolbar } from "@mui/material";

import NavList from "./NavList";

const toolbarStyle = {
  minHeight: "61px",
};

export default function SideBar() {
  return (
    <Drawer
      anchor={"left"}
      variant="permanent"
      elevation={0}
      sx={{
        width: 256,
        zIndex: 2,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 256,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar style={toolbarStyle} variant="regular" />
      <Box sx={{ overflow: "auto" }}>
        <NavList />
      </Box>
    </Drawer>
  );
}
