import React from "react";

import { Grid } from "@mui/material";

import OrderCard from "../../components/purchase-orders/cards/OrderCard";
import OrdersList from "../../components/purchase-orders/lists/OrdersList";

const PurchaseOrders = () => (
  <Grid
    container
    spacing={4}
    sx={{
      paddingLeft: { xs: 4 },
      paddingRight: { xs: 4 },
      backgroundColor: "#E5E5E5",
      height: "100%",
    }}
  >
    <Grid item display="flex">
      <OrdersList />
    </Grid>
    <Grid item display="flex" flexGrow={1}>
      <OrderCard />
    </Grid>
  </Grid>
);

export default PurchaseOrders;
