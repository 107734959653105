import React from "react";

import { Box, Grid } from "@mui/material";

import Order from "../../data/Order";
import OrdersGraphData from "../../data/OrdersGraphData";
import PurchaseOrder from "../../data/PurchaseOrder";
import DashboardSpinner from "../DashboardSpinner";
import CurrentEstimateWidget from "./CurrentEstimateWidget";
import { OrdersGraph } from "./OrdersGraph/OrdersGraph";
import OrdersList, { DashboardColumn } from "./OrdersList";
import PurchaseOrdersList from "./PurchaseOrdersList";
import StocktakesWidget from "./StocktakesWidget";
import { listPadding } from "./styles";
import RidersWidget from "./RidersWidget";

import { AvailableRider, DashboardRider } from "../../data/Riders";

const OrdersGrid = (props: {
  orders: Order[];
  purchaseOrders: PurchaseOrder[] | undefined;
  ordersGraphData: OrdersGraphData;
  isToRideStackingEnabled: boolean;
  advancedView: boolean;
  riders: DashboardRider[] | undefined;
  availableRiders: AvailableRider[] | undefined;
  setAdvancedView: (advancedView: boolean) => void;
}) => {
  const { riders, availableRiders } = props;
  const toPickOrders = props.orders?.filter(
    (order) => order.latestStatus === "orderCreated"
  );
  const pickingOrders = props.orders?.filter(
    (order) => order.latestStatus === "pickingStarted"
  );
  const toRideOrders = props.orders?.filter(
    (order) => order.latestStatus === "pickingCompleted"
  );
  const rideStartedOrders = props.orders?.filter(
    (order) => order.latestStatus === "rideStarted"
  );
  const recentlyDeliveredOrders = props.orders?.filter(
    (order) => order.fulfilledTimestamp !== null
  );

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 1, xl: 3 }}
      sx={{
        justifyContent: "center",
        padding: { xs: 2, lg: 1, xl: 4 },
        paddingLeft: { xl: 12 },
        paddingRight: { xl: 12 },
        paddingBottom: 0,
        flexGrow: 1,
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <Grid
        item
        md={(12 / 5) * 2}
        xs={12}
        sx={{ display: "flex", height: "100%" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            gap: listPadding,
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            display="flex"
            flexDirection={{ md: "row", xs: "column" }}
            sx={{
              gap: listPadding,
              height: "70%",
              width: "100%",
            }}
          >
            <Box
              display="flex"
              flexDirection={{ md: "row", xs: "column" }}
              sx={{
                gap: listPadding,
                height: "100%",
                width: "100%",
              }}
            >
              <OrdersList
                name={"🥕 To pick"}
                orders={props.orders}
                columnOrders={toPickOrders}
                riders={riders}
                availableRiders={availableRiders}
                dashboardColumn={DashboardColumn.toPick}
                advancedView={props.advancedView}
                isToRideStackingEnabled={props.isToRideStackingEnabled}
                grow
              />
            </Box>
            <Box
              display="flex"
              flexDirection={{ md: "row", xs: "column" }}
              sx={{
                gap: listPadding,
                height: "100%",
                width: "100%",
              }}
            >
              <OrdersList
                name={"🛒 Picking"}
                orders={props.orders}
                columnOrders={pickingOrders}
                riders={riders}
                availableRiders={availableRiders}
                dashboardColumn={DashboardColumn.picking}
                advancedView={props.advancedView}
                isToRideStackingEnabled={props.isToRideStackingEnabled}
                grow
              />
            </Box>
          </Box>
          <Box
            display="flex"
            sx={{
              gap: listPadding,
              height: "30%",
              width: "100%",
            }}
          >
            <OrdersGraph rawData={props.ordersGraphData} />
          </Box>
        </Box>
      </Grid>

      <Grid item md={12 / 5} xs={12} sx={{ display: "flex", height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            gap: listPadding,
            height: "100%",
            width: "100%",
          }}
        >
          <OrdersList
            name={"⏳ To ride"}
            orders={props.orders}
            columnOrders={toRideOrders}
            riders={riders}
            availableRiders={availableRiders}
            dashboardColumn={DashboardColumn.toRide}
            advancedView={props.advancedView}
            isToRideStackingEnabled={props.isToRideStackingEnabled}
            grow
          />
          <RidersWidget availableRiders={availableRiders} />
          <StocktakesWidget />
        </Box>
      </Grid>
      <Grid item md={12 / 5} xs={12} sx={{ display: "flex", height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ gap: listPadding, height: "100%", width: "100%" }}
        >
          <OrdersList
            name={"🏇 Riding"}
            orders={props.orders}
            columnOrders={rideStartedOrders}
            riders={riders}
            availableRiders={availableRiders}
            dashboardColumn={DashboardColumn.riding}
            advancedView={props.advancedView}
            isToRideStackingEnabled={props.isToRideStackingEnabled}
            grow
          />
          {props.purchaseOrders && (
            <PurchaseOrdersList purchaseOrders={props.purchaseOrders} />
          )}
          {!props.purchaseOrders && <DashboardSpinner />}
        </Box>
      </Grid>
      <Grid item md={12 / 5} xs={12} sx={{ display: "flex", height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            gap: listPadding,
            height: "100%",
            width: "100%",
          }}
        >
          <OrdersList
            name={"✅ Recent orders"}
            orders={props.orders}
            columnOrders={recentlyDeliveredOrders}
            riders={riders}
            availableRiders={availableRiders}
            dashboardColumn={DashboardColumn.delivered}
            advancedView={props.advancedView}
            isToRideStackingEnabled={props.isToRideStackingEnabled}
            grow
          />
          <CurrentEstimateWidget />
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrdersGrid;
