import { createState, useState } from "@hookstate/core";

import { AdminActions, useAuthState } from "../../authentication/AuthState";
import {
  SpilloverData,
  StaffingFilter,
  StaffingForecast,
  StaffingForecastResponse,
} from "../../data/StaffingForecast";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { useStaffingStoresAndDates } from "./StaffingStoresAndDatesState";

const currentDate = moment
  .tz("Europe/Stockholm")
  .startOf("day")
  .format("YYYY-MM-DD");

const initialState = {
  forecastData: undefined as StaffingForecast | undefined,
  filter: {
    storeId: 1 as 1 | number,
    forecastDateFrom: currentDate as string | null,
    forecastDateTo: undefined as string | undefined,
    orderFactor: 0 as number | null,
    putrFactor: 0 as number | null,
    absenceFactor: 0 as number | null,
  } as StaffingFilter,
  isLoading: false,
  isGenerating: false,
  updateInterval: null as ReturnType<typeof setInterval> | null,
  spillover: null as SpilloverData | null,
};

const staffingForecastState = createState(initialState);
const invalidTokenMessage = "Invalid token, resetting staffing redirect";

export const useStaffingForecast = () => {
  const { dates } = useStaffingStoresAndDates();
  const state = useState(staffingForecastState);
  const authState = useAuthState();
  function stopGenerating() {
    const updateInterval = state.updateInterval.get();
    if (updateInterval) {
      state.isGenerating.set(false);
      clearInterval(updateInterval);
    }
  }
  function handleForcastData(forecast: {
    status: number;
    invalidToken: boolean;
    data: any;
  }) {
    const staffingForecastResponse = forecast.data as StaffingForecastResponse;
    const forecastData = staffingForecastResponse?.result?.[0];
    if (!forecastData) {
      toast.error("Data does not exist on selected store and date");
    } else {
      state.forecastData.set(forecastData);
      stopGenerating();
    }

    state.isLoading.set(false);
  }

  return {
    get forecastData() {
      return state.forecastData.get();
    },
    get filter() {
      return state.filter.get();
    },
    setFilter(newFilter: Partial<StaffingFilter>) {
      return state.filter.merge(newFilter);
    },
    resetFilter() {
      return state.filter.set({
        ...state.filter.get(),
        putrFactor: 0,
        orderFactor: 0,
        absenceFactor: 0,
      });
    },
    async fetchStaffingForecast(
      storeId: number,
      dateRange: { startDate: string; endDate: string | undefined }
    ) {
      state.isLoading.set(true);
      const isGenerating = state.isGenerating.get();
      if (isGenerating) {
        toast.error("Cancelling request..");
        stopGenerating();
      }

      const forecast = await authState.authenticatedRequest(
        AdminActions.GET_STAFFING_FORECAST,
        {
          storeId,
          dateRange,
        }
      );

      handleForcastData(forecast);
    },
    async createRiderSchedule() {
      const {
        storeId,
        forecastDateFrom,
        forecastDateTo,
        putrFactor,
        orderFactor,
        absenceFactor,
      } = state.filter.get();

      if (storeId === null || forecastDateFrom === null) {
        toast.error("Missing Store or Forecast Date");
        return;
      }

      if (state.isGenerating.get()) {
        toast.error("Already generating shifts..");
        return;
      }

      state.isLoading.set(true);
      state.isGenerating.set(true);
      toast.dark("Generating new shifts..");

      const prevUpdateTimestamp = new Date().toISOString();

      const dateRange = {
        startDate: forecastDateFrom,
        endDate: forecastDateTo ?? undefined,
      };

      const response = await authState.authenticatedRequest(
        AdminActions.CREATE_RIDER_SCHEDULE,
        {
          storeId,
          dateRange,
          pUTRUplift: Number(putrFactor) / 100 || undefined,
          orderUplift: Number(orderFactor) / 100 || undefined,
          absenceUplift: Number(absenceFactor) / 100 || undefined,
        }
      );

      if (response.data.result === "Failed to create rider schedule") {
        toast.error("Failed to generate shift");
        state.isLoading.set(false);
        state.isGenerating.set(false);
        return;
      }

      if (response.invalidToken) {
        state.isLoading.set(false);
        state.isGenerating.set(false);
        console.warn(invalidTokenMessage);
        toast.error(invalidTokenMessage);
        return;
      }
      state.updateInterval.set(
        setInterval(async () => {
          const forecasts = await authState.authenticatedRequest(
            AdminActions.GET_STAFFING_FORECAST,
            {
              storeId,
              dateRange,
            }
          );

          const forecastsData = forecasts.data as StaffingForecastResponse;

          const isCorrectGraphToShow =
            forecastsData.result[0]?.forecastDate === forecastDateFrom;

          const allForecastsGenerated = forecastsData.result.every(
            (date) =>
              prevUpdateTimestamp &&
              date.latestUpdateTimestamp &&
              prevUpdateTimestamp < date.latestUpdateTimestamp
          );
          if (allForecastsGenerated) {
            if (!isCorrectGraphToShow) {
              toast.error("Could not get graph. Please refresh and try again!");
              stopGenerating();
              return;
            }

            toast.dark("Got new data");
            handleForcastData(forecasts);
          }
        }, 1000 * 5)
      );
    },
    async submitRiderSchedule() {
      const { filter } = state.get();
      if (!filter.forecastDateFrom) {
        toast.warning("Please select a from date!");
        return;
      }
      const { storeId } = state.filter.get();

      if (!storeId) {
        toast.error("Error! Missing store id!");
        return;
      }

      toast.dark("Submitting rider schedule");

      const response = await authState.authenticatedRequest(
        AdminActions.SUBMIT_RIDER_SCHEDULE,
        { storeId, dates: datesToSubmit(filter, dates) }
      );

      if (response.invalidToken) {
        console.warn(invalidTokenMessage);
        toast.error(invalidTokenMessage);
        return;
      }

      if (response?.data.result === "Failed to submit rider schedule") {
        toast.error(response?.data.result);
        return;
      }
    },
    get isLoading() {
      return state.isLoading.get();
    },
    get isGenerating() {
      return state.isGenerating.get();
    },
  };
};

function datesToSubmit(filter: StaffingFilter, dates: string[]) {
  if (filter.forecastDateTo) {
    const fromIndex = dates.indexOf(filter.forecastDateFrom);
    const toIndex = dates.indexOf(filter.forecastDateTo);
    return dates.slice(fromIndex, toIndex + 1);
  }
  return [filter.forecastDateFrom];
}
