import React, { ReactNode } from "react";

import { Box } from "@mui/material";

const InCircle = (props: { color: string; children: ReactNode }) => (
  <Box
    height={{ xs: 20, xl: 32 }}
    width={{ xs: 20, xl: 32 }}
    sx={{ backgroundColor: props.color, borderRadius: { xs: 2.5, xl: 4 } }}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    {props.children}
  </Box>
);

export default InCircle;
