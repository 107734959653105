import React, { ReactNode } from "react";

import { Stack } from "@mui/material";

import AdvancedViewSwitch from "../dashboard/AdvancedViewSwitch";
import StoreSelect from "../dashboard/StoreSelect";
import Unmute from "../dashboard/Unmute";
import { headerHeight, headerPadding } from "../dashboard/styles";

const DashboardHeaderLayout = (props: {
  children?: ReactNode | ReactNode[];
  advancedView: boolean;
  setAdvancedView: (advancedView: boolean) => void;
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        minHeight: headerHeight,
        paddingLeft: headerPadding,
        paddingRight: headerPadding,
        display: "flex",
        alignItems: "center",
        backgroundColor: "background.paper",
        boxShadow: "0px 1px 5px #EEEDED, 0px 4.42px 25px #F9F9F9",
      }}
    >
      {props.children}
      <Stack
        direction="row"
        display="flex"
        justifyContent="flex-end"
        flexGrow={1}
        gap={2}
      >
        <StoreSelect />
        <AdvancedViewSwitch {...props} />
        <Unmute />
      </Stack>
    </Stack>
  );
};

export default DashboardHeaderLayout;
