import React, { useCallback, useEffect, useState } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { useStoreState } from "../../data/StoreState";

const StoreSelect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [storeId, setStoreId] = useState("1");

  useEffect(() => {
    const searchStoreId = searchParams.get("store");
    if (searchStoreId) {
      setStoreId(searchStoreId);
    }
  }, [searchParams.get("store"), setStoreId]);

  const setStore = useCallback(
    (event: SelectChangeEvent) => {
      const newStoreId = Number(event.target.value);
      localStorage.setItem("defaultStore", String(newStoreId));
      if (storeId) {
        navigate(`${location.pathname}?store=${newStoreId}`);
      }
    },
    [navigate]
  );

  const { stores } = useStoreState();

  return (
    <FormControl variant="standard" sx={{ width: 150, marginRight: 3 }}>
      <InputLabel id="store-select-label">Store</InputLabel>
      <Select
        labelId="store-select-label"
        id="store-select"
        displayEmpty
        value={storeId}
        onChange={setStore}
        placeholder="Store"
        label="Store"
        sx={{ fontSize: { xs: 12, xl: 16 } }}
      >
        {stores.map((store) => (
          <MenuItem value={store.id} key={store.id}>
            {store.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StoreSelect;
