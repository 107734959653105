import React, { useEffect, useRef, useState } from "react";

import { Navigate, useLocation } from "react-router-dom";

import { CssBaseline, Stack } from "@mui/material";

import logo from "../assets/kavall_login_logo.png";
import config from "../config";
import pages from "../pages/Pages";
import { useAuthState } from "./AuthState";
import LocationState from "./LocationState";

const authCallback = async (
  state: any,
  googleResponse: google.CredentialResponse
) => {
  const token = googleResponse.credential;

  try {
    const data = {
      data: {
        token,
        action: "AUTH",
      },
    };

    const response = await fetch(config.apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();
    const { email, actions } = json.result;

    state.authorize(token, email, actions);
    console.log({ token, email, actions });
  } catch (e) {
    console.error(e);
    state.unauthorize(token);
  }
};

const LoginButton: React.FC = () => {
  const googleRenderButton = useRef(null);

  useEffect(() => {
    if (googleRenderButton?.current) {
      google.accounts.id.renderButton(googleRenderButton.current, {
        type: "standard",
        theme: "outline",
        size: "large",
        text: "signin_with",
        logo_alignment: "center",
        width: 400,
        locale: "en_GB",
      });
    }
  }, [googleRenderButton.current]);

  return <span ref={googleRenderButton} />;
};

export default function Login() {
  const authState = useAuthState();
  const location = useLocation();

  const { from } = location.state as LocationState;

  const navigateTo = from + location.search;

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: config.oAuthClientId,
      callback: (credentialResponse) =>
        authCallback(authState, credentialResponse),
      cancel_on_tap_outside: false,
      auto_select: true,
    });
    setInitialized(true);
  }, []);

  const homePath =
    pages.find((page) => authState.userHasPermission(page.action))?.path || "/";

  return authState.status !== "authorized" ? (
    <React.Fragment>
      <CssBaseline />
      <Stack spacing={10} alignItems="center" sx={{ pt: "26.4674vh" }}>
        <img src={logo} alt="Kavall-logo" width={398} />
        {initialized && <LoginButton />}
      </Stack>
    </React.Fragment>
  ) : (
    <Navigate
      to={navigateTo || homePath}
      state={{ from: location.pathname }}
      replace
    />
  );
}
