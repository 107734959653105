import { createState, useState } from "@hookstate/core";
import { AdminActions, useAuthState } from "../authentication/AuthState";

interface Store {
  id: number;
  name: string;
}

const initialState = {
  stores: [] as Store[],
};

type StoreState = typeof initialState;

const storeState = createState<StoreState>(initialState);

export function useStoreState() {
  const state = useState(storeState);
  const authState = useAuthState();

  if (state.stores.length === 0) {
    void authState
      .anonymousRequest(AdminActions.GET_STORES, null)
      .then((response) => {
        state.merge({ stores: response.data.result });
      });
  }

  return {
    get stores() {
      return state.stores.get();
    },
  };
}
