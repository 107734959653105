import React from "react";

import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import { hourglassSize } from "./styles";

const Hourglass = (props: { white?: boolean | undefined }) => {
  const color = props.white ? "#ffffff" : "#1c1c1c";

  return (
    <HourglassEmptyIcon
      sx={{
        color,
        fontSize: hourglassSize,
      }}
    />
  );
};

export default Hourglass;
