import React from "react";

import { Typography } from "@mui/material";

const OrderSupplier = (props: { supplier: string }) => (
  <Typography noWrap sx={{ fontWeight: 400, fontSize: 20, color: "#747474" }}>
    {props.supplier}
  </Typography>
);

export default OrderSupplier;
