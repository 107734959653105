import React, { ChangeEvent, useEffect } from "react";
import Header from "../../components/Header";
import { useStaffingForecast } from "./StaffingForecastState";
import { useStaffingStoresAndDates } from "./StaffingStoresAndDatesState";
import {
  Box,
  CircularProgress,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Filters from "../../components/staffing-forecast/Filters";
import GraphLineInfo from "../../components/staffing-forecast/GraphLineInfo";
import ForecastChart from "../../components/staffing-forecast/ForecastChart";
import KPIStrip from "../../components/staffing-forecast/KPIStrip";
import PlannedSpillover from "../../components/staffing-forecast/PlannedSpillover";
import { toast } from "react-toastify";

const StaffingForecast = () => {
  const { stores, dates, fetchStaffingStoresAndDates } =
    useStaffingStoresAndDates();

  const {
    filter,
    forecastData,
    setFilter,
    fetchStaffingForecast,
    createRiderSchedule,
    submitRiderSchedule,
    isLoading,
    isGenerating,
  } = useStaffingForecast();

  const spillover = forecastData?.quarters.map((quart) => {
    return {
      time: quart.time,
      spillover: Number(
        (quart.orders - quart.ridersPlanned * quart.utr).toFixed(2)
      ),
    };
  });

  const currentWeekday = new Date(
    forecastData?.forecastDate ?? ""
  ).toLocaleDateString("en-EN", {
    weekday: "long",
  });

  const storeInfo = {
    name: forecastData?.storeName ?? "",
    day: currentWeekday === "Invalid Date" ? "" : currentWeekday,
    date: forecastData?.forecastDate ?? "",
  };

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });

    if (!filter.storeId || !filter.forecastDateFrom) return;

    if (e.target.name === "storeId" || e.target.name === "forecastDateFrom") {
      setFilter({
        ...filter,
        forecastDateTo: undefined,
      });
      fetchStaffingForecast(filter.storeId, {
        startDate: filter.forecastDateFrom,
        endDate: filter.forecastDateTo,
      });
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    if (e.nativeEvent.submitter.name === "generate") {
      createRiderSchedule();
    }
    if (e.nativeEvent.submitter.name === "submit") {
      submitRiderSchedule();
    }
  }

  function handleAbort() {
    if (!filter.storeId || !filter.forecastDateFrom) return;
    fetchStaffingForecast(filter.storeId, {
      startDate: filter.forecastDateFrom,
      endDate: filter.forecastDateTo,
    });
  }

  useEffect(() => {
    fetchStaffingStoresAndDates();
    if (!filter.storeId || !filter.forecastDateFrom) {
      toast.error("Please select store and date");
      return;
    }
    fetchStaffingForecast(filter.storeId, {
      startDate: filter.forecastDateFrom,
      endDate: filter.forecastDateTo,
    });
  }, []);

  return (
    <div>
      <Header />
      {dates && stores && (
        <Grid
          container
          sx={{
            marginY: "4rem",
            paddingY: "0px",
            paddingX: "2rem",
          }}
        >
          <Typography
            component="h1"
            mt={1}
            sx={{ fontSize: "28px", fontWeight: "bold" }}
          >
            Staffing Dashboard
          </Typography>
          <Grid item xs={12} marginY={2}>
            <Box display="flex">
              <Grid item xs={12}>
                <Filters
                  onHandleAbort={handleAbort}
                  isLoading={isLoading}
                  isGenerating={isGenerating}
                  storeInfo={storeInfo}
                  onHandleSubmit={handleSubmit}
                  dates={dates}
                  stores={stores}
                  onHandleChange={handleChange}
                />
              </Grid>
            </Box>
            <GraphLineInfo />
            {isLoading && (
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  height: "50vh",
                }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography alignItems="center" fontSize={32} marginRight={1}>
                    Loading graph
                  </Typography>
                  <CircularProgress
                    size={32}
                    sx={{ color: "#773fca", textAlign: "center" }}
                  />
                </Box>
              </Box>
            )}
            {forecastData && !isLoading && (
              <>
                <ForecastChart forecastData={forecastData} />
                <KPIStrip forecastData={forecastData} />
              </>
            )}

            {spillover && !isLoading && (
              <>
                <Typography
                  textAlign="center"
                  fontSize="20px"
                  color="#606060"
                  marginY={3}
                >
                  Planned Spillover
                </Typography>
                <PlannedSpillover spilloverData={spillover} />
              </>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default StaffingForecast;
