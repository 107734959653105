import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { StaffingForecast } from "../../data/StaffingForecast";
import { getChartTicks } from "./helpers/getNumberOfTicks";

type Props = {
  forecastData: StaffingForecast;
};

const ForecastChart = (props: Props) => {
  const { forecastData } = props;

  const formattedData = forecastData.quarters.map((quart) => {
    return {
      time: quart.time,
      utr: (quart.utr * 4).toFixed(2),
      riderDemand: quart.riderDemand.toFixed(1),
      ridersProposed: quart.ridersProposed,
      ridersPlanned: quart.ridersPlanned,
      orders: quart.orders.toFixed(0),
    };
  });

  return (
    <ResponsiveContainer height={450}>
      <LineChart
        data={formattedData ?? []}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="1 1" />
        <XAxis
          height={40}
          dy={10}
          dx={2}
          xAxisId={0}
          dataKey="time"
          angle={270}
          tick={{ fontSize: "10px" }}
          interval={0}
        />
        <XAxis
          dy={10}
          label={{ value: "UTR", angle: 0, position: "left" }}
          xAxisId={1}
          dataKey="utr"
          angle={270}
          tick={{ fontSize: "10px", fill: "#6620d4" }}
          interval={0}
        />
        <YAxis
          yAxisId={1}
          dy={10}
          label={{ value: "Riders", angle: "270" }}
          type="number"
          interval={0}
          domain={getChartTicks(forecastData, "riders")}
          ticks={getChartTicks(forecastData, "riders")}
        />
        <YAxis
          yAxisId={2}
          dy={10}
          orientation="right"
          label={{ value: "Orders", angle: "270" }}
          type="number"
          interval={0}
          domain={getChartTicks(forecastData, "orders")}
          ticks={getChartTicks(forecastData, "orders")}
        />
        <Tooltip />
        <Line
          yAxisId={2}
          dataKey="orders"
          name="Orders"
          stroke="#fbc568"
          strokeWidth={1}
          dot={false}
        />
        <Line
          yAxisId={1}
          dataKey="riderDemand"
          name="Rider Demand"
          strokeWidth={2}
          stroke="#6525c4"
          dot={false}
        />
        <Line
          yAxisId={1}
          dataKey="ridersProposed"
          name="Riders Proposed"
          strokeWidth={2}
          stroke="#000000"
          dot={false}
        />
        <Line
          yAxisId={1}
          dataKey="ridersPlanned"
          name="Planned Riders"
          strokeWidth={2}
          stroke="#8cbe3d"
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ForecastChart;
