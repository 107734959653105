import React from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

import { useOrdersState } from "../../pages/Dashboard/DashboardState";
import NumberBox from "./OrderCard/NumberBox";
import {
  boldFontWeight,
  darkGrey,
  listBorder,
  listBorderRadius,
  listPadding,
  widgetHeaderFontSize,
} from "./styles";

const StocktakesWidget = () => {
  const { stocktakesCount } = useOrdersState();

  return (
    <Box
      sx={{
        padding: listPadding,
        border: listBorder,
        borderRadius: listBorderRadius,
        flexShrink: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: widgetHeaderFontSize,
          fontWeight: boldFontWeight,
          color: darkGrey,
        }}
      >
        Stock takes to do
      </Typography>
      {(stocktakesCount !== undefined && (
        <NumberBox number={stocktakesCount} bold />
      )) || <CircularProgress />}
    </Box>
  );
};

export default StocktakesWidget;
