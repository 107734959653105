import moment from "moment";
import { DashboardRider } from "../../../data/Riders";

function getSecondsAvailable(availableRider: DashboardRider) {
  const now = moment();
  const secondsAvailable = moment
    .duration(now.diff(availableRider?.availableSinceTimestamp))
    .asSeconds()
    .toFixed(0);
  return Number(secondsAvailable);
}

export default getSecondsAvailable;
