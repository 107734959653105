import { createState, useState } from "@hookstate/core";

import {
  AdminActions,
  UseAuthState,
  useAuthState,
} from "../../authentication/AuthState";
import Performance, {
  OrdersPerformance,
  Picker,
  PickersShiftsSummary,
  Rider,
  RiderRaw,
  RiderStatus,
  RidersShiftsSummary,
} from "../../data/Performance";

const initialState = {
  isLoadingPerformance: false,
  ridersShiftsSummary: undefined as RidersShiftsSummary | undefined,
  riders: undefined as Rider[] | undefined,
  pickersShiftsSummary: undefined as PickersShiftsSummary | undefined,
  pickers: undefined as Picker[] | undefined,
  storeId: undefined as number | undefined,
  orders: undefined as OrdersPerformance | undefined,
};

const performanceDashboardState = createState(initialState);

let startedInterval = false;

export const loadPerformance = async (
  authState: UseAuthState,
  storeId: number
) => {
  const response = await authState.anonymousRequest(
    AdminActions.GET_PERFORMANCE_DASHBOARD,
    {
      storeId,
    }
  );

  return response.data.result as Performance;
};

const performanceItemToRider = (item: RiderRaw): Rider => {
  const name = `${item.first_name} ${item.last_name}`;
  return {
    ...item,
    status: item.status === "Idle" ? RiderStatus.Standby : item.status,
    name,
  };
};

const sortRiders = (rider1: Rider, rider2: Rider) => {
  const statusValues = Object.values(RiderStatus);

  return (
    // By status ascending
    statusValues.indexOf(rider1.status) - statusValues.indexOf(rider2.status) ||
    // By status duration descending
    rider2.status_duration_minutes - rider1.status_duration_minutes
  );
};

export function usePerformanceDashboardState() {
  const state = useState(performanceDashboardState);

  const authState = useAuthState();

  const performanceInterval = async () => {
    const storeId = state.storeId.get();

    if (!storeId) {
      return;
    }

    const performance = await loadPerformance(authState, storeId);

    const ridersShiftsSummary =
      "shiftsSummary" in performance
        ? performance.shiftsSummary
        : performance.riders.shiftsSummary;
    const riders =
      "riders" in performance
        ? performance.riders.performance
        : performance.performanceData;
    const orders = "orders" in performance ? performance.orders : undefined;
    const pickersShiftsSummary =
      "pickers" in performance ? performance.pickers.shiftsSummary : undefined;
    const pickers =
      "pickers" in performance ? performance.pickers.performance : undefined;

    state.isLoadingPerformance.set(true);
    state.merge({
      ridersShiftsSummary,
      riders: riders.map(performanceItemToRider).sort(sortRiders),
      pickersShiftsSummary,
      pickers: pickers
        ?.map((picker, i) => ({
          ...picker,
          employeeId: i + 1,
          name: `${picker.firstName} ${picker.lastName}`,
        }))
        .sort((picker1, picker2) => (picker2.utr ?? 0) - (picker1.utr ?? 0)),
      orders,
      isLoadingPerformance: false,
    });
  };

  if (!startedInterval) {
    startedInterval = true;
    performanceInterval();
    setInterval(performanceInterval, 15000);
  }

  return {
    get isLoadingPerformance() {
      return state.isLoadingPerformance.get();
    },
    get ridersShiftsSummary() {
      return state.ridersShiftsSummary.get();
    },
    get riders() {
      return state.riders.get();
    },
    get pickersShiftsSummary() {
      return state.pickersShiftsSummary.get();
    },
    get pickers() {
      return state.pickers.get();
    },
    get orders() {
      return state.orders.get();
    },
    setStoreId(storeId: number) {
      state.storeId.set(storeId);
      state.riders.set(undefined);
      state.ridersShiftsSummary.set(undefined);
      state.pickersShiftsSummary.set(undefined);
      performanceInterval();
    },
  };
}
