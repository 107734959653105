import React from "react";
import { Box } from "@mui/material";
import Order from "../../../data/Order";
import useStackColor from "../../../hooks/useStackColor";
import { borderRadius, cardHeight } from "../styles";
import Assignee from "./Assignee";
import OrderStatusBar from "./OrderStatusBar";
import StackMarker from "./StackMarker";
import { AvailableRider, DashboardRider } from "../../../data/Riders";
import getAvailableRider from "../helpers/getAvailableRider";
import { TrafficLightStatuses } from "../helpers/getTrafficLightStatus";
import getStackStatus from "../helpers/getStackStatus";

const OrderCard = (props: {
  order: Order;
  orders: Order[];
  riders: DashboardRider[] | undefined;
  availableRiders: AvailableRider[] | undefined;
  advancedView: boolean;
  isToRideStackingEnabled: boolean;
}) => {
  const {
    order,
    orders,
    availableRiders,
    advancedView,
    isToRideStackingEnabled,
  } = props;

  const assignee =
    order.latestStatus === "rideStarted" || order.fulfilledTimestamp !== null
      ? order.rider
      : order.picker;

  const suggestedRider = getSuggestedRider(order);

  const pickerDisplayName = order.picker ?? "-";

  const showSuggestedRider =
    suggestedRider &&
    (order.isLockedFromRestacking || advancedView || showWaitingRider(orders));

  const sx = {
    height: cardHeight(showAssignee()),

    width: "100%",
    background: "#ffffff",
    border: 1,
    borderColor: "#E9E9E9",
    borderRadius: borderRadius,
    padding: 0,
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
  };

  const stackColor = useStackColor(order);
  const bagPickingInProgress = order.bagPickingCompleted === false;
  const hasLockedRestackingPartner = order.hasLockedRestackingPartner;
  const isManuallyHandled = order.isManuallyHandled;

  function isBeforeToRide(latestStatus: string): boolean {
    return (
      latestStatus === "orderCreated" ||
      latestStatus === "pickingStarted" ||
      latestStatus === "pickingCompleted"
    );
  }

  function showWaitingRider(orders: Order[]) {
    if (!orders || !isWaitingRider(availableRiders, suggestedRider))
      return false;

    const availableRider = getAvailableRider(availableRiders, suggestedRider);

    if (!availableRider) return false;

    const { mostUrgentStackId } = getStackStatus(orders, availableRider);

    const unlockedOrdersToRide = orders
      .filter((order) => order.latestStatus === "pickingCompleted")
      .some((order) => !order.isLockedFromRestacking);

    if (!unlockedOrdersToRide) return false;

    return order.orderStackId === mostUrgentStackId;
  }

  function showPickerAndSuggestedRider() {
    return showSuggestedRider && isBeforeToRide(order.latestStatus);
  }

  function showAssignee() {
    if (
      isBeforeToRide(order.latestStatus) &&
      !order.picker &&
      !showSuggestedRider
    ) {
      return false;
    }
    return true;
  }

  function getAssigneeToShow() {
    if (!showAssignee()) {
      return;
    }

    if (
      (order.latestStatus === "rideStarted" ||
        order.fulfilledTimestamp !== null) &&
      assignee
    ) {
      return <Assignee assignee={assignee} />;
    }

    if (showPickerAndSuggestedRider() && suggestedRider) {
      return (
        <Assignee
          order={order}
          picker={pickerDisplayName}
          suggestedRider={suggestedRider}
        />
      );
    }
    return <Assignee picker={pickerDisplayName} />;
  }

  return (
    <Box sx={sx}>
      <StackMarker
        advancedView={advancedView}
        isToRideStackingEnabled={isToRideStackingEnabled}
        stackColor={stackColor}
        withHourglass={bagPickingInProgress}
        hasLockedRestackingPartner={hasLockedRestackingPartner}
        isManuallyHandled={isManuallyHandled}
      />
      <Box
        flexGrow={1}
        flexDirection={"column"}
        display={"flex"}
        sx={{ marginRight: 1, marginLeft: { xs: 1, md: 1, xl: 3 } }}
      >
        {getAssigneeToShow()}
        <OrderStatusBar {...props} />
      </Box>
    </Box>
  );
};

function getSuggestedRider(order: Order) {
  if (
    order.latestStatus !== "rideStarted" &&
    order.fulfilledTimestamp === null
  ) {
    if (order.suggestedRider) {
      return order.suggestedRider.name;
    }
    if (order.isManuallyHandled) {
      return "*MANUAL*";
    }
  }
  return null;
}

function isWaitingRider(
  availableRiders: AvailableRider[] | undefined,
  suggestedRider: string | null
) {
  if (!availableRiders || !suggestedRider) {
    return;
  }
  const availableRider = getAvailableRider(availableRiders, suggestedRider);

  return availableRider?.trafficLightStatus === TrafficLightStatuses.Wait;
}

export default OrderCard;
