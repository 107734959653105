import React, { ReactNode } from "react";

import { Card, Stack, Typography } from "@mui/material";

import { boldFontWeight } from "../dashboard/styles";
import { performanceCardStyle } from "./styles";

const DashboardCard = ({
  title,
  header,
  children,
  sx,
}: {
  title?: string;
  header?: ReactNode;
  children?: ReactNode;
  sx?: any;
}) => {
  return (
    <Card sx={{ ...performanceCardStyle, ...sx }}>
      <Stack
        sx={{ padding: 0.5 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {title ? (
          <Typography fontSize={{ xs: 20, xl: 28 }} fontWeight={boldFontWeight}>
            {title}
          </Typography>
        ) : null}
        {header}
      </Stack>
      {children}
    </Card>
  );
};

export default DashboardCard;
