import React, { useCallback } from "react";

import moment, { Moment } from "moment";

import { FormControl, TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";

import { usePurchaseOrdersState } from "../../../pages/PurchaseOrders/PurchaseOrdersState";

const DateFilter = () => {
  const { filter, setFilter } = usePurchaseOrdersState();

  const value = filter.date ? moment(filter.date) : null;

  const onChange = useCallback(
    (value: Moment | null) =>
      setFilter({ date: value?.format("YYYY-MM-DD") || undefined }),
    [setFilter]
  );

  return (
    <FormControl variant="filled" sx={{ width: 160 }}>
      <MobileDatePicker
        inputFormat="YYYY-MM-DD"
        value={value}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} placeholder="Date" />}
        clearable
      />
    </FormControl>
  );
};

export default DateFilter;
