import { createState, useState } from "@hookstate/core";

import { AdminActions, useAuthState } from "../../authentication/AuthState";

const initialState = {
  loading: true,
  storeId: null as null | number,
  date: null as null | string,
  pUTRUplift: undefined as undefined | number,
  orderUplift: undefined as undefined | number,
  absenceUplift: undefined as undefined | number,
  filenames: [] as string[],
  messages: [] as string[],
  status: "ADJUST_RIDER_SCHEDULE" as
    | "ADJUST_RIDER_SCHEDULE"
    | "ADJUST_RIDER_SCHEDULE_DONE"
    | "SUBMIT_RIDER_SCHEDULE"
    | "SUBMIT_RIDER_SCHEDULE_DONE"
    | "INVALID_INPUT"
    | "COMPLETED_PLEASE_CLOSE_TAB",
};

const staffingRedirectState = createState(initialState);

export const useStaffingRedirectState = () => {
  const state = useState(staffingRedirectState);
  const authState = useAuthState();

  return {
    async createRiderSchedule() {
      const {
        storeId,
        date,
        pUTRUplift,
        orderUplift,
        messages,
        absenceUplift,
      } = state.get();

      if (storeId === null || date === null) {
        state.messages.set(
          messages.concat([
            `Error! Could not identify storeId ${storeId} or date ${date}`,
          ])
        );
        return;
      }

      const message = `Creating rider schedule for store ID: ${storeId} and date ${date} with ${
        pUTRUplift ?? "default"
      } pUTRUplift, ${orderUplift ?? "default"} orderUplift and ${
        absenceUplift ?? "default"
      } absenceUplift`;

      state.messages.set(messages.concat([message]));

      const response = await authState.authenticatedRequest(
        AdminActions.CREATE_RIDER_SCHEDULE,
        {
          storeId,
          dateRange: {
            startDate: date,
            endDate: undefined,
          },
          pUTRUplift,
          orderUplift,
          absenceUplift,
        }
      );

      console.log(JSON.stringify(response));

      if (response.invalidToken) {
        console.warn(`Invalid token, resetting staffing redirect`);
        state.messages.set([]);
        return;
      }

      state.loading.set(false);
      state.status.set("ADJUST_RIDER_SCHEDULE_DONE");
      state.messages.set(
        state.messages
          .get()
          .concat([
            response?.data?.result,
            "It's now safe to close this browser tab!",
          ])
      );
      this.clearStateAfterCompletion();
    },

    async submitRiderSchedule() {
      const { filenames, messages } = state.get();

      if (filenames.length === 0) {
        state.messages.set(
          messages.concat([
            `Error! Could not identify rider schedule to upload`,
          ])
        );
        return;
      }

      const message = `Submitting rider schedule for ${JSON.stringify(
        filenames
      )}`;

      state.messages.set(messages.concat([message]));

      const response = await authState.authenticatedRequest(
        AdminActions.SUBMIT_RIDER_SCHEDULE,
        filenames
      );

      console.log(JSON.stringify(response));

      if (response.invalidToken) {
        console.warn(`Invalid token, resetting staffing redirect`);
        state.messages.set([]);
        return;
      }

      state.loading.set(false);
      state.status.set("SUBMIT_RIDER_SCHEDULE_DONE");
      state.messages.set(
        state.messages
          .get()
          .concat([
            response?.data?.result,
            "It's now safe to close this browser tab!",
          ])
      );
      this.clearStateAfterCompletion();
    },

    clearStateAfterCompletion() {
      history.replaceState(null, "", "/staffing-redirect?done");
      state.storeId.set(null);
    },

    setStateFromURL(search: string) {
      console.log(`Parsing URL params: ${search}`);
      if (search.includes("?done")) {
        state.status.set("COMPLETED_PLEASE_CLOSE_TAB");
        state.messages.set([
          "Please close this page, and go to the 'Staffing' Tableau dashboard in order to place a new staffing schedule.",
          "If you need help, contact us in the #data Slack channel. (This page is usually shown if you reload the page)",
        ]);
        return;
      }
      if (search.includes("filenames")) {
        // Expected example: ?filenames=2022-12-26/StoreId_1--MadeDate_2022-12-06T13:02:27.087Z--UTR_2--Uplift_1.json,2022-12-26/StoreId_1--MadeDate_2022-12-06T13:02:27.087Z--UTR_2--Uplift_2.json
        state.status.set("SUBMIT_RIDER_SCHEDULE");
        const filenames = (search.split("filenames=")[1]?.split("&")[0] || "")
          .split(",")
          .map((name) => decodeURIComponent(name));
        state.filenames.set(filenames);
        return;
      }
      // Expected example: ?storeId=1&startDate=2022-12-23&utr=2.4&uplift=1.1
      state.status.set("ADJUST_RIDER_SCHEDULE");
      const storeId = Number(search.split("storeId=")[1]?.split("&")[0]);
      const startDate = search.split("startDate=")[1]?.split("&")[0];
      const orderUplift = Number(
        search.split("orderUplift=")[1]?.split("&")[0]
      );
      const pUTRUplift = Number(search.split("pUTRUplift=")[1]?.split("&")[0]);
      const absenceUplift = Number(
        search.split("absenceUplift=")[1]?.split("&")[0]
      );
      if (!startDate || !storeId) {
        state.status.set("INVALID_INPUT");
        state.messages.set(
          state.messages
            .get()
            .concat([
              `Please copy/screenshot this and send to #data Slack channel. Attempted params: ${search}`,
            ])
        );
        return;
      }
      state.storeId.set(storeId);
      state.date.set(startDate);
      state.orderUplift.set(orderUplift || undefined);
      state.pUTRUplift.set(pUTRUplift || undefined);
      state.absenceUplift.set(absenceUplift || undefined);
    },
    get loading() {
      return state.loading.get();
    },
    get messages() {
      return state.messages.get();
    },
    get redirectRequest() {
      return state.status.get();
    },
  };
};
