interface ChangePurchaseOrderStatusRequest {
  orderNumber: string;
  newStatus: newPurchaseOrderStatus;
}

export enum newPurchaseOrderStatus {
  Open = "Open",
  Unknown = "Unknown",
}

export default ChangePurchaseOrderStatusRequest;
