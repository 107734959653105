import React from "react";

import DashboardHeaderLayout from "../layouts/DashboardHeaderLayout";
import Logo from "../dashboard/Logo";

const PerformanceDashboardHeader = (props: {
  advancedView: boolean;
  setAdvancedView: (advancedView: boolean) => void;
}) => (
  <DashboardHeaderLayout {...props}>
    <Logo />
  </DashboardHeaderLayout>
);

export default PerformanceDashboardHeader;
