import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import { SpilloverData } from "../../data/StaffingForecast";
import { getChartTicks } from "./helpers/getNumberOfTicks";

type Props = {
  spilloverData: SpilloverData[];
};

const PlannedSpillover = (props: Props) => {
  const { spilloverData } = props;
  return (
    <ResponsiveContainer height={250}>
      <AreaChart
        data={spilloverData}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" vertical={false} />
        <XAxis
          dy={6}
          dx={2}
          xAxisId={0}
          dataKey="time"
          angle={270}
          tick={{ fontSize: "10px" }}
          interval={0}
        />

        <YAxis
          yAxisId={0}
          dy={10}
          label={{ value: "Orders per quarter", angle: "270" }}
          type="number"
          ticks={getChartTicks(spilloverData, "spillover")}
          domain={getChartTicks(spilloverData, "spillover")}
          interval={0}
        />
        <Tooltip
          labelStyle={{ color: "#773fca" }}
          itemStyle={{ color: "#773fca" }}
        />
        <Area dataKey="spillover" fill="#6620d4" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default PlannedSpillover;
