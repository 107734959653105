import React from "react";

import DashboardHeaderLayout from "../layouts/DashboardHeaderLayout";
import Logo from "./Logo";

const DashboardHeader = (props: {
  advancedView: boolean;
  setAdvancedView: (advancedView: boolean) => void;
}) => {
  return (
    <DashboardHeaderLayout {...props}>
      <Logo />
    </DashboardHeaderLayout>
  );
};

export default DashboardHeader;
