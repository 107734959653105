import { Button, Grid } from "@mui/material";
import React from "react";

type IButtonProps = {
  content: string;
  theme: { backgroundColor: string; color: string };
  name: string;
  isGenerating: boolean;
  isLoading: boolean;
};

const SubmitButton = (props: IButtonProps) => {
  const { content, theme, name, isGenerating, isLoading } = props;
  return (
    <Grid display="flex" gap={2}>
      <Button
        disabled={isGenerating || isLoading}
        name={name}
        type="submit"
        variant="contained"
        disableElevation
        sx={{
          backgroundColor: theme.backgroundColor,
          color: theme.color,
          height: "100px",
          width: "150px",
          "&:hover": { backgroundColor: theme.backgroundColor },
        }}
      >
        {content}
      </Button>
    </Grid>
  );
};

export default SubmitButton;
