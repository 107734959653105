import React from "react";

import { Box, Typography } from "@mui/material";

import PurchaseOrder from "../../data/PurchaseOrder";
import { boldFontWeight, poFontSize } from "./styles";

const colors = {
  red: {
    backgroundColor: "#FFF2F2",
    color: "#F03737",
  },
  yellow: {
    backgroundColor: "#FFF7EC",
    color: "#EF9B20",
  },
  green: {
    backgroundColor: "#EBFEEE",
    color: "#45BC58",
  },
};

const PurchaseOrderChip = (props: { purchaseOrder: PurchaseOrder }) => {
  const completedRate =
    props.purchaseOrder.itemsCompleted / props.purchaseOrder.itemsTotal;

  const colorRate =
    completedRate > 0.75 ? "green" : completedRate > 0.5 ? "yellow" : "red";
  const { backgroundColor, color } = colors[colorRate];

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor,
        color,
        padding: { xs: "3px 9px", xl: "4px 12px" },
        borderRadius: 6,
        marginLeft: "auto",
      }}
    >
      <Typography sx={{ fontSize: poFontSize }}>
        {props.purchaseOrder.itemsCompleted}
      </Typography>
      <Typography sx={{ fontSize: poFontSize }}>{"\u00A0/\u00A0"}</Typography>
      <Typography sx={{ fontWeight: boldFontWeight, fontSize: poFontSize }}>
        {props.purchaseOrder.itemsTotal}
      </Typography>
    </Box>
  );
};

export default PurchaseOrderChip;
