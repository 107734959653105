import React from "react";

import { Box, Typography } from "@mui/material";

import { usePerformanceDashboardState } from "../../pages/PerformanceDashboard/PerformanceDashboardState";
import DashboardSpinner from "../DashboardSpinner";
import ShiftSummary, { Shift } from "./ShiftSummary";

const ShiftsSummary = () => {
  const { ridersShiftsSummary: shiftsSummary } = usePerformanceDashboardState();

  if (!shiftsSummary) {
    return <DashboardSpinner />;
  }

  const { morning, evening } = shiftsSummary;

  return (
    performance && (
      <Box display="flex" gap={{ xs: 1, xl: 2.5 }} alignItems="center">
        <Typography
          fontSize={{ xs: 11, xl: 14 }}
          fontWeight={500}
          sx={{
            width: { xs: 60, xl: 120 },
            wordWrap: "break-word",
            lineHeight: "80%",
          }}
        >
          Shifts summary:
        </Typography>
        <ShiftSummary
          shift={Shift.morning}
          minutes={morning.actualMinutes}
          minutesTarget={morning.targetMinutes}
        />
        <ShiftSummary
          shift={Shift.evening}
          minutes={evening.actualMinutes}
          minutesTarget={evening.targetMinutes}
        />
      </Box>
    )
  );
};

export default ShiftsSummary;
