import moment, { Moment } from "moment";

export interface APIOrder {
  displayNumber: number;
  estimatedDeliveryWithoutDelays: string;
  latestStatus: string;
  latestStatusTimestamp: string;
  color: number | null;
  orderStackId: number | null;
  picker?: string;
  rider?: string;
  suggestedRider?: SuggestedRider | null;
  sortTimestamp: string;
  isLockedFromRestacking: boolean;
  createdTimestamp: string;
  fulfilledTimestamp: string | null;
  isManuallyHandled: boolean;
  plannedOrderCreationTimestamp?: Date | null;
  bagsCount: number;
  itemsCount: number;
  hasFrozenProduct: boolean;
  isFailedDelivery: boolean;
}

interface SuggestedRider {
  name: string;
}

interface Order extends APIOrder {
  isLatched: boolean;
  stackEstimatedDeliveryWithoutDelays: string;
  stackPickingCompletedTimestamp: string | null;
  bagPickingCompleted: boolean | null;
  minutesLeft: number;
  delayed: boolean;
  hasLockedRestackingPartner: boolean;
  minutesWaiting: number;
  wasPickedLessThanTenSecAgo: boolean;
  assignee: string | undefined;
  deliveryTimeInMinutes: number;
  isStacked: boolean;
}

export const getStackEstimatedDeliveryWithoutDelays = (orders: APIOrder[]) => {
  return orders.reduce((map, order) => {
    if (order.orderStackId) {
      const currentEstimatedDeliveryWithoutDelays = map.get(order.orderStackId);
      const newEstimatedDeliveryWithoutDelays =
        order.estimatedDeliveryWithoutDelays;

      if (
        !currentEstimatedDeliveryWithoutDelays ||
        newEstimatedDeliveryWithoutDelays <
          currentEstimatedDeliveryWithoutDelays
      ) {
        map.set(order.orderStackId, newEstimatedDeliveryWithoutDelays);
      }
    }
    return map;
  }, new Map<number, string>());
};

export const getPickingCompletedTimestampPerStack = (orders: APIOrder[]) => {
  return orders.reduce((map, order) => {
    if (order.orderStackId) {
      const currentPickingCompletedTimestamp = map.get(order.orderStackId);
      const newPickingCompletedTimestamp =
        order.latestStatus === "pickingCompleted"
          ? order.latestStatusTimestamp
          : null;

      if (currentPickingCompletedTimestamp === undefined) {
        map.set(order.orderStackId, newPickingCompletedTimestamp);
      } else if (currentPickingCompletedTimestamp !== null) {
        if (
          newPickingCompletedTimestamp === null ||
          newPickingCompletedTimestamp > currentPickingCompletedTimestamp
        ) {
          map.set(order.orderStackId, newPickingCompletedTimestamp);
        }
      }
    }
    return map;
  }, new Map<number, string | null>());
};

export const hasLockedRestackingPartner = (
  order: Order,
  allOrders: APIOrder[]
) => {
  if (!order.orderStackId) {
    return false;
  }

  const sameStackOrders = allOrders.filter(
    (anotherOrder) => anotherOrder.orderStackId === order.orderStackId
  );

  return sameStackOrders.some(
    (anotherOrder) => anotherOrder.isLockedFromRestacking
  );
};

export const wasPickedLessThanTenSecAgo = (order: Order) => {
  if (
    order.latestStatus !== "pickingCompleted" ||
    !order.latestStatusTimestamp
  ) {
    return false;
  }

  return (
    moment.duration(moment().diff(order.latestStatusTimestamp)).asSeconds() < 10
  );
};

export const isBagPickingCompleted = (order: Order) => {
  if (order.latestStatus !== "pickingCompleted") {
    return null;
  }

  const pickingCompletedTimestamp = order.orderStackId
    ? order.stackPickingCompletedTimestamp
    : order.latestStatusTimestamp;

  return Boolean(pickingCompletedTimestamp);
};

export const isStackedOrder = (order: APIOrder, orders: APIOrder[]) => {
  return orders.some(
    (anotherOrder) =>
      anotherOrder.orderStackId === order.orderStackId &&
      anotherOrder.displayNumber !== order.displayNumber
  );
};

export const isStackedAndSamePartnerStatus = (
  order: APIOrder,
  orders: APIOrder[]
) => {
  if (!order.orderStackId) {
    return false;
  }

  const sameStackOrders = orders.filter(
    (anotherOrder) => anotherOrder.orderStackId === order.orderStackId
  );

  return sameStackOrders.some(
    (anotherOrder) => anotherOrder.latestStatus !== order.latestStatus
  );
};

const minutesFromNow = (timestamp: Moment) => {
  return Math.ceil(
    moment.duration(moment(timestamp).diff(moment())).asMinutes()
  );
};

export const getMinutesLeft = (order: Order) => {
  return minutesFromNow(moment(order.estimatedDeliveryWithoutDelays));
};

export const getMinutesWaiting = (order: Order) => {
  return Math.floor(
    moment
      .duration(
        moment().diff(
          moment(order.plannedOrderCreationTimestamp || order.createdTimestamp)
        )
      )
      .asMinutes()
  );
};

export const getOrderAssignee = (order: Order) =>
  order.latestStatus === "rideStarted" || order.fulfilledTimestamp !== null
    ? order.rider
    : order.picker;

export const getDeliveryTimeInMinutes = (order: Order) => {
  let deliveryTimeInMinutes = 0;
  if (order.fulfilledTimestamp) {
    const createdAt = new Date(
      order.plannedOrderCreationTimestamp || order.createdTimestamp
    );
    const fulfilledAt = new Date(order.fulfilledTimestamp);
    const deliveryTimeInMs = Math.abs(
      fulfilledAt.getTime() - createdAt.getTime()
    );
    deliveryTimeInMinutes = Math.floor(deliveryTimeInMs / 60000);
  }

  return deliveryTimeInMinutes;
};

export default Order;
