import React from "react";

import { Box, Typography } from "@mui/material";

import { useOrdersState } from "../../pages/Dashboard/DashboardState";
import TimeBox from "./TimeBox";
import {
  boldFontWeight,
  darkGrey,
  listBorder,
  listBorderRadius,
  listPadding,
  widgetHeaderFontSize,
} from "./styles";

const CurrentEstimateWidget = () => {
  const { currentDeliveryEstimate } = useOrdersState();

  return (
    <Box
      sx={{
        padding: listPadding,
        border: listBorder,
        borderRadius: listBorderRadius,
        flexShrink: 10,
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: darkGrey,
          fontSize: widgetHeaderFontSize,
          fontWeight: boldFontWeight,
        }}
      >
        Pre-ride estimate
      </Typography>
      <TimeBox isPreRideEstimate={true} minutes={currentDeliveryEstimate} />
    </Box>
  );
};

export default CurrentEstimateWidget;
