import React from "react";

import moment from "moment";

import { Box, Typography } from "@mui/material";

import PurchaseOrder from "../../data/PurchaseOrder";
import PurchaseOrderChip from "./PurchaseOrderChip";
import { boldFontWeight, poFontSize } from "./styles";

const PurchaseOrderCard = (props: { purchaseOrder: PurchaseOrder }) => {
  const number = props.purchaseOrder.number;
  const date = moment(props.purchaseOrder.date).format("MM/DD");

  return (
    <Box
      component="div"
      sx={{
        height: { xs: "33px", md: "22px", lg: "33px", xl: "80px" },
        padding: 0,
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography sx={{ fontSize: poFontSize }}>{date}</Typography>
      <Typography
        sx={{
          fontSize: poFontSize,
          fontWeight: boldFontWeight,
          marginLeft: { xs: 1, xl: 2 },
        }}
      >
        {number}
      </Typography>
      <Typography
        flexShrink={2}
        sx={{
          fontSize: { xs: "13px", md: "9px", lg: "13px", xl: "31px" },
          marginLeft: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          color: "#8494A3",
        }}
      >
        {props.purchaseOrder.supplierName}
      </Typography>
      <PurchaseOrderChip purchaseOrder={props.purchaseOrder} />
    </Box>
  );
};

export default PurchaseOrderCard;
