import React from "react";

import { VolumeMute } from "@mui/icons-material";
import { Box } from "@mui/material";

import { useOrdersState } from "../../pages/Dashboard/DashboardState";

const Line = () => {
  return (
    <svg
      width="20px"
      height="20px"
      style={{
        fill: "none",
        strokeWidth: 2,
        strokeLinecap: "round",
        position: "absolute",
        zIndex: 1,
      }}
    >
      <line stroke="#6620D4" x1="20" y1="0" x2="0" y2="20" />
      <line stroke="#FFF" x1="20" y1="3" x2="3" y2="20" />
    </svg>
  );
};

const Unmute = () => {
  const { soundOn, setSoundOn } = useOrdersState();

  const muted = !soundOn;

  const borderColor = muted ? "primary.main" : "grey.300";
  const color = "#6620D4";
  const onClick = () => setSoundOn(!soundOn);

  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
        alignItems: "center",
        justifyContent: "center",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        cursor: "pointer",
        marginRight: 2,
        borderColor,
        transition: "background-color 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: "#F5EFFE",
          outline: "none",
        },
        "&:active": {
          backgroundColor: "#D1B7FF",
        },
      }}
      onClick={onClick}
    >
      {muted && <Line />}
      <VolumeMute
        sx={{
          color,
          fontSize: 42,
          position: "relative",
        }}
      />
    </Box>
  );
};

export default Unmute;
