import React from "react";

import { Box, Stack } from "@mui/material";

import OrdersGraphData from "../../../data/OrdersGraphData";
import { OrdersChart } from "./OrdersGraphChart";
import { OrdersGraphHeader } from "./OrdersGraphHeader";

export function OrdersGraph(props: { rawData: OrdersGraphData }) {
  return (
    <Stack
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        maxHeight: "100%",
      }}
    >
      <Box>
        <OrdersGraphHeader />
      </Box>
      <Box
        sx={{
          height: "100%",
        }}
      >
        <OrdersChart {...props} />
      </Box>
    </Stack>
  );
}
