import { darkGrey, fontWeight } from "../styles";
import { Box, Typography } from "@mui/material";
import Order from "../../../data/Order";
import moment from "moment-timezone";
import TimeBox from "../TimeBox";
import React from "react";

const OrderMinutes = ({ order }: { order: Order }) => {
  const isDeliveredOrder = Boolean(order.fulfilledTimestamp);

  const estimatedDeliveryMoment = moment(order.estimatedDeliveryWithoutDelays);
  const fulfilledMoment = moment(order.fulfilledTimestamp || undefined);
  const timeDiff = estimatedDeliveryMoment.diff(fulfilledMoment, "minutes");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        verticalAlign: "bottom",
        alignItems: "center",
      }}
    >
      {!isDeliveredOrder && (
        <Typography
          sx={{
            color: darkGrey,
            whiteSpace: "nowrap",
            fontSize: {
              xs: "12px",
              md: "10px",
              lg: "12px",
              lg2: "20px",
              xl: "46px",
            },
            fontWeight: fontWeight,
          }}
        >
          {timeDiff} m
        </Typography>
      )}
      {isDeliveredOrder && (
        <TimeBox
          minutes={timeDiff}
          isPlannedOrder={Boolean(order.plannedOrderCreationTimestamp)}
        />
      )}
    </Box>
  );
};

export default OrderMinutes;
