import React from "react";

import { Link, useLocation } from "react-router-dom";

import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useAuthState } from "../authentication/AuthState";
import config from "../config";
import pages from "../pages/Pages";

export default function NavList() {
  const authState = useAuthState();
  const location = useLocation();

  return (
    <List
      sx={{
        paddingTop: 0,
      }}
    >
      <Divider key={"initial-divider"} />
      {pages.map(function (page) {
        const selected = location.pathname === page.path;
        const enabledForEnvironment = config.isDev
          ? true
          : page.enableForProduction;
        const userHasPermission = authState.userHasPermission(page.action);

        return enabledForEnvironment && userHasPermission ? (
          <React.Fragment key={page.name}>
            <ListItem
              component={Link}
              disablePadding
              to={page.path}
              sx={{
                height: 45,
                color: "#252D4A",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "130%",
                [`& .Mui-selected`]: {
                  color: "#342094",
                  background: "rgba(240, 233, 251, 0.7) !important",
                },
              }}
            >
              <ListItemButton selected={selected} sx={{ height: 45 }}>
                <ListItemIcon
                  sx={{
                    color: selected ? "#342094" : "#777789",
                  }}
                >
                  <page.icon />
                </ListItemIcon>
                <ListItemText primary={page.name} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ) : null;
      })}
    </List>
  );
}
