import React, { useEffect } from "react";

import { Card } from "@mui/material";

import { usePurchaseOrdersState } from "../../../pages/PurchaseOrders/PurchaseOrdersState";
import ListHeader from "../ListHeader";
import ListFilters from "../filters/ListFilters";
import OrdersDataGrid from "../grids/OrdersDataGrid";

const OrdersList = () => {
  const state = usePurchaseOrdersState();

  useEffect(() => {
    if (state.loading) {
      state.fetchPurchaseOrders();
    }
  }, [state.loading]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        width: 680,
        flexGrow: 1,
        padding: 3,
        borderRadius: 2.5,
        gap: 3,
      }}
    >
      <ListHeader />
      <ListFilters />
      <OrdersDataGrid />
    </Card>
  );
};

export default OrdersList;
