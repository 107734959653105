import React from "react";

import { Box, Typography } from "@mui/material";

import { boldFontWeight } from "../dashboard/styles";
import { shiftsColors, shiftsTextColors } from "./styles";

const ShiftLabel = (props: { shift: "morning" | "evening" }) => {
  const shiftColor = shiftsColors[props.shift];
  const shiftTextColor = shiftsTextColors[props.shift];

  return (
    <Box
      width={{ xs: 90, xl: 120 }}
      height={{ xs: 24, xl: 32 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ backgroundColor: shiftColor, borderRadius: 4 }}
    >
      <Typography
        fontWeight={boldFontWeight}
        fontSize={{ xs: 12, xl: 20 }}
        color={shiftTextColor}
        sx={{ textTransform: "uppercase" }}
      >
        {props.shift}
      </Typography>
    </Box>
  );
};

export default ShiftLabel;
