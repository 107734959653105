import React, { useCallback } from "react";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Box, IconButton } from "@mui/material";

import { isSafari } from "../../utils";

const GoFullScreenButton = () => {
  const display = isSafari() ? "none" : "flex";

  const onClick = useCallback(() => {
    const element = document.getElementById("dashboard");
    if (!element) {
      return;
    }
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "10%",
        right: "10%",
        zIndex: 5,
        height: 64,
        width: 64,
        borderRadius: "100px",
        backgroundColor: "#CCC",
        display,
        justifyContent: "center",
        alignItems: "center",
        "@media (max-width: 900px)": {
          display: "none",
        },
      }}
      onClick={onClick}
    >
      <IconButton
        color="primary"
        size="large"
        aria-label="upload picture"
        sx={{ fontSize: 48 }}
      >
        <FullscreenIcon />
      </IconButton>
    </Box>
  );
};

export default GoFullScreenButton;
