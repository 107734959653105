import React, { useState } from "react";

import moment from "moment";

import { Box, Typography } from "@mui/material";
import { GridColDef, GridSortDirection, GridSortModel } from "@mui/x-data-grid";

import { Picker, PickersShiftsSummary } from "../../data/Performance";
import { usePerformanceDashboardState } from "../../pages/PerformanceDashboard/PerformanceDashboardState";
import DashboardSpinner from "../DashboardSpinner";
import { boldFontWeight } from "../dashboard/styles";
import PerformanceDataGrid from "./PerformanceDataGrid";
import ShiftLabel from "./ShiftLabel";

const pickTimeValueGetter = (row: Picker): string => {
  const pickTime = moment.duration((row.avgPickingTime || 0) * 1000);

  const pickTimeStr =
    pickTime.minutes() + ":" + pickTime.seconds().toString().padStart(2, "0");

  const pickTimePerItem = row.avgPickingTimePerItem || 0;

  return `${pickTimeStr} (${pickTimePerItem.toFixed(0)} sec)`;
};

const pickedOrdersValueGetter = (row: Picker): string => {
  return `${row.orders || 0} (${row.utr?.toFixed() || 0}/h)`;
};

const renderShiftNameCell = (params: {
  row: { name: "morning" | "evening" };
}) => {
  const name = params.row.name;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={1}
      sx={{ flexGrow: 1, padding: 0 }}
    >
      <Typography fontSize={{ xs: 12, xl: 24 }} fontWeight={boldFontWeight}>
        Summary shift
      </Typography>
      <ShiftLabel shift={name} />
    </Box>
  );
};

const columns: GridColDef[] = [
  {
    headerName: "Name",
    flex: 2.5,
    field: "name",
  },
  {
    headerName: "Pick time (/item)",
    flex: 1.5,
    field: "avgPickingTime",
    valueGetter: (params) => pickTimeValueGetter(params.row),
  },
  {
    headerName: "PFR",
    flex: 1,
    field: "pfr",
    valueGetter: (params) => ((params.row.pfr || 0) * 100).toFixed(2) + " %",
  },
  {
    headerName: "Picked orders (UTR)",
    flex: 1.5,
    field: "orders",
    valueGetter: (params) => pickedOrdersValueGetter(params.row),
  },
];

const PickersDataGrid = (props: {
  pickers: Picker[];
  sortModel: GridSortModel;
  setSortModel: (sortModel: GridSortModel) => void;
}) => (
  <PerformanceDataGrid
    rows={props.pickers || []}
    columns={columns}
    sortModel={props.sortModel}
    setSortModel={props.setSortModel}
    getRowId={(row: Picker) => row.employeeId}
  />
);

const ShiftsSummaryDataGrid = (props: {
  shiftsSummary: PickersShiftsSummary;
  sortModel: GridSortModel;
}) => {
  const summaries = [
    { name: "morning", ...props.shiftsSummary.morning },
    { name: "evening", ...props.shiftsSummary.evening },
  ];

  const shiftsColumns: GridColDef[] = [
    {
      headerName: "Name",
      flex: 2.5,
      field: "name",
      renderCell: renderShiftNameCell,
    },
    ...columns.slice(1),
  ];

  return (
    <Box sx={{ marginTop: 5 }}>
      <PerformanceDataGrid
        rows={summaries}
        columns={shiftsColumns}
        hideHeader
        sortModel={props.sortModel}
        getRowId={(row) => row.name}
      />
    </Box>
  );
};

const PickersGrid = () => {
  const {
    pickers,
    isLoadingPerformance,
    pickersShiftsSummary: shiftsSummary,
  } = usePerformanceDashboardState();

  const [sortModel, setSortModel] = useState<
    { field: string; sort: GridSortDirection }[]
  >([]);

  if (!pickers || !shiftsSummary || isLoadingPerformance) {
    return <DashboardSpinner />;
  }

  return (
    <Box marginTop={1.5}>
      <PickersDataGrid
        pickers={pickers}
        sortModel={sortModel}
        setSortModel={setSortModel}
      />
      <ShiftsSummaryDataGrid
        shiftsSummary={shiftsSummary}
        sortModel={sortModel}
      />
    </Box>
  );
};

export default PickersGrid;
