import React from "react";

import { Box, Typography } from "@mui/material";

import { usePurchaseOrdersState } from "../../pages/PurchaseOrders/PurchaseOrdersState";
import OrderDeliveryDate from "./OrderDeliveryDate";
import OrderNumber from "./OrderNumber";
import OrderSupplier from "./OrderSupplier";
import PurchaseOrderFulfillment from "./PurchaseOrderFulfillment";

const OrderHeader = () => {
  const { purchaseOrder } = usePurchaseOrdersState();

  return purchaseOrder ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: 3,
        alignItems: "center",
        marginBottom: 7,
      }}
    >
      <OrderNumber number={purchaseOrder.number} />
      <OrderSupplier supplier={purchaseOrder.supplierName} />
      <Typography sx={{ fontWeight: 400, fontSize: 20, color: "#747474" }}>
        DELIVERY
      </Typography>
      <OrderDeliveryDate date={purchaseOrder.date} />
      <PurchaseOrderFulfillment {...purchaseOrder} variant="admin" />
    </Box>
  ) : null;
};

export default OrderHeader;
